import React from "react"
import { Button, Col, Icon, Row, Table, Tooltip } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import { JsonPathPicker } from "react-json-path-picker"
import { FiLogIn } from "react-icons/fi"

import * as MappingsHelper from "../../services/ingest/mappings"

const ONE_SECOND_TIMEOUT = 1000
const DICTIONARY_HIGHLIGHT_CLASS = "dict-mapped"
const MAPPING_TYPE_ERROR_CLASS = "dict-mapping-error"

class IngestMappings extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    /**
     * @TODO assigning directly props to state is a bad practice in React
     * but, due to time constraints, I really need to reduce complexity of
     * calculating other properties from this.props.xxx
     * Might be rewriten as _getMappings() somewhere
     */
    this.state = {
      path: "",
      preloadedMetadataTables: [],
    }

    this.previousSelectedPath = ""

    this._onEventKeyChoose = this._onEventKeyChoose.bind(this)
    this._handleMapping = this._handleMapping.bind(this)
    this._nextStep = this._nextStep.bind(this)
    this._generatePreview = this._generatePreview.bind(this)
    this._renderColumnWithTooltip = this._renderColumnWithTooltip.bind(this)
  }

  componentDidMount() {
    const { mappings = [], enrichments } = this.props

    if (Array.isArray(enrichments) && enrichments.length) {
      MappingsHelper.getMetadataTablesInUse(
        enrichments,
        mappings,
        this.props.event
      ).then(preloadedMetadataTables => {
        this.setState({ preloadedMetadataTables })
      })
    }
  }

  static _isObject(obj) {
    return !!(obj && typeof obj === "object" && obj.constructor === Object)
  }

  get DICTIONARY_COLUMNS() {
    return [
      {
        key: "index",
        render: () => (
          <div className="clickable">
            <FiLogIn />
          </div>
        ),
        width: 60,
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-type"),
        dataIndex: "type",
        key: "type",
      },
    ]
  }

  get PREVIEW_COLUMNS() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "destination",
        key: "destination",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-value"),
        dataIndex: "value",
        key: "value",
        render: this._renderColumnWithTooltip,
      },
    ]
  }

  _renderColumnWithTooltip(text, record) {
    let tooltipMessage = ""
    if (record.typeError) {
      tooltipMessage = this._formatMessage(
        "new-ingestion-type-incompatibilities-mapping"
      )
    } else if (record.tsIndexDateError) {
      tooltipMessage = this._formatMessage(
        "new-ingestion-ts-index-not-iso-8601-mapping"
      )
    }
    if (tooltipMessage) {
      return (
        <p>
          <span style={{ marginRight: "5px" }}>{text}</span>
          <Tooltip title={tooltipMessage}>
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        </p>
      )
    } else {
      return (
        <p>
          <span>{text}</span>
        </p>
      )
    }
  }

  _onMappingsChange(mappings) {
    typeof this.props.onMappingsChange === "function" &&
      this.props.onMappingsChange(mappings)
  }

  _handleMapping(record) {
    const { path = "" } = this.state
    if (MappingsHelper._isValidPath(this.props.event, path)) {
      try {
        const { mappings } = this.props
        const mappingIndex = mappings.findIndex(
          ({ destination }) => destination === record.name
        )
        const value = MappingsHelper._searchPathValueInEvent(
          this.props.event,
          path
        )
        const mapping = {
          path: path[0] === "." ? path.slice(1) : path,
          mapped: true,
          type: record.type,
          destination: record.name,
          error: !MappingsHelper._mappingIsOK(value, record.type),
        }
        if (mappingIndex >= 0) {
          mappings[mappingIndex] = mapping
        } else {
          mappings.push(mapping)
        }
        this.setState({ path: "" }, () => {
          setTimeout(() => {
            Array.from(
              document.querySelectorAll(`.${DICTIONARY_HIGHLIGHT_CLASS}`)
            ).forEach(elem => {
              if (elem.closest("li")) {
                elem.classList.remove(DICTIONARY_HIGHLIGHT_CLASS)
              }
            })
            if (mappings[mappingIndex]) {
              delete mappings[mappingIndex].mapped
            }
            this._onMappingsChange(mappings, mapping)
          }, ONE_SECOND_TIMEOUT)
        })
      } catch (err) {
        console.error(`[ERROR] On mapping: ${err}`)
      }
    }
  }

  _onEventKeyChoose(path) {
    if (MappingsHelper._isValidPath(this.props.event, path)) {
      this.setState({ path }, () => {
        const pathInDomAttribute = path
          .replace(/\./g, " .")
          .replace(/\[/g, " [")
          .replace(/\"/g, "")
        const previousSelected = this.previousSelectedPath
          ? document.querySelector(
              `[data-querykey="${this.previousSelectedPath}"]`
            )
          : null
        const previousSelectedPathRow =
          previousSelected && previousSelected.closest("li")
        if (previousSelectedPathRow) {
          previousSelectedPathRow.classList.remove(DICTIONARY_HIGHLIGHT_CLASS)
          this.previousSelectedPath = ""
        }

        /*
         * This is a bad practice since we shouldn't know about what a component encapsules. But it's done
         * since there aren't similar components in the community & this component has a very limited API
         */
        const clickedJsonValue = document.querySelector(
          `[data-querykey="${pathInDomAttribute}"]`
        )
        const selectedPathRow =
          clickedJsonValue && clickedJsonValue.closest("li")
        if (selectedPathRow) {
          selectedPathRow.classList.toggle(DICTIONARY_HIGHLIGHT_CLASS)
          this.previousSelectedPath = pathInDomAttribute
        }
      })
    }
  }

  _generatePreview(
    mappings,
    transformations,
    enrichments,
    dictionaryFields,
    event,
    preloadedMetadataTables
  ) {
    return MappingsHelper._generatePreview(
      transformations,
      enrichments,
      mappings,
      dictionaryFields,
      event,
      preloadedMetadataTables
    )
  }

  _nextStep() {
    typeof this.props.onNextStep === "function" && this.props.onNextStep()
  }

  render() {
    const disabled = !this.props.mappings || this.props.mappings.length < 2

    return (
      <Row>
        <Row>
          <Col span={8}>
            <h3>{this._formatMessage("event")}</h3>
            <div className="blackboard contentLimited">
              {this.props.event !== null && this.props.event !== undefined && (
                <JsonPathPicker
                  json={
                    this.props.event ? JSON.stringify(this.props.event) : {}
                  }
                  onChoose={this._onEventKeyChoose}
                  path={this.state.path}
                />
              )}
            </div>
          </Col>
          <Col span={7} offset={1}>
            <h3>{this._formatMessage("models-dictionary")}</h3>
            <Table
              className="ingests-table-mappings"
              size="small"
              rowKey="name"
              pagination={false}
              columns={this.DICTIONARY_COLUMNS}
              dataSource={this.props.dictionaryFields}
              rowClassName={record => {
                const mapped =
                  this.props.mappings.findIndex(
                    mapping => mapping.destination === record.name
                  ) !== -1
                return mapped ? DICTIONARY_HIGHLIGHT_CLASS : ""
              }}
              locale={{
                emptyText: this._formatMessage("no-data"),
              }}
              onRow={record => {
                return {
                  onClick: () => this._handleMapping(record),
                }
              }}
            />
          </Col>
          <Col span={7} offset={1}>
            <h3>{this._formatMessage("preview")}</h3>
            <Table
              className="ingests-table-mappings"
              size="small"
              pagination={false}
              columns={this.PREVIEW_COLUMNS}
              dataSource={this._generatePreview(
                this.props.mappings,
                this.props.transformations,
                this.props.enrichments,
                this.props.dictionaryFields,
                this.props.event,
                this.state.preloadedMetadataTables
              )}
              rowClassName={record =>
                record.typeError || record.tsIndexDateError
                  ? MAPPING_TYPE_ERROR_CLASS
                  : ""
              }
              locale={{
                emptyText: this._formatMessage("no-data"),
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col span={28}>
            <Button type="primary" onClick={this._nextStep} disabled={disabled}>
              {this._formatMessage("next-step")}
            </Button>
          </Col>
        </Row>
      </Row>
    )
  }
}

export default injectIntl(IngestMappings)
