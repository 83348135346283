import React from "react"
import { Tag } from "antd"

const IngestTag = ({ children }) => {
  const styles = {
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    margin: "2px",
    borderRadius: "15px",
    backgroundColor: "transparent",
    color: "#868D96",
    borderColor: "#868D96",
  }

  return <Tag style={styles}>{children}</Tag>
}

export default IngestTag
