import React from "react"
import { Col, Form, message, Row, Spin, Steps } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import _ from "lodash"

import { INGEST_TYPES } from "../../services/constants"
import { callbackRolesErrorMessages } from "../../services/roles/rolesRequestErrorMessages"
import IngestDataForm from "./ingestDataForm"
import IngestDictionaryForm from "./ingestDictionaryForm"
import IngestContextSelector from "./ingestContextSelector"
import IngestEventForm from "./ingestEventForm"
import IngestMappings from "./ingestMappings"
import IngestActivate from "./ingestActivate"
import IngestOthers from "./ingestOthers"
import * as IngestService from "../../services/ingest"

const { Step } = Steps
const MARGIN = 40
const FIXED_OPTION = "fixed"
const FILTERED_OPTION = "filtered"
const INFORMED_OPTION = "informed"

class BatchIngestForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      roles: [],
      sites: [],
      sources: [],
      prefixes: [],
      context: null,
      metadataTables: [],
      editMode: false,
      disabledSteps: true,
      mappings: null,
      enrichments: null,
      transformations: null,
      event: null,
      headersProvidedSeparately: null,
      headersOrder: undefined,
      businessUnits: [],
    }

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this._onStepChange = this._onStepChange.bind(this)
    this._renderCurrentStep = this._renderCurrentStep.bind(this)
    this._onIngestNameChange = this._onIngestNameChange.bind(this)
    this._onIngestAliasChange = this._onIngestAliasChange.bind(this)
    this._onIngestDescriptionChange = this._onIngestDescriptionChange.bind(this)
    this._onIngestEmailChange = this._onIngestEmailChange.bind(this)
    this._onIngestTagsChange = this._onIngestTagsChange.bind(this)
    this._onVersionChange = this._onVersionChange.bind(this)
    this._onNewIngestContextChange = this._onNewIngestContextChange.bind(this)
    this._onDictionarySelect = this._onDictionarySelect.bind(this)
    this._onEventChange = this._onEventChange.bind(this)
    this._onHeadersProvidedSeparatelyChange = this._onHeadersProvidedSeparatelyChange.bind(
      this
    )
    this._onSeparatorChange = this._onSeparatorChange.bind(this)
    this._goToNextStep = this._goToNextStep.bind(this)
    this._getIngest = this._getIngest.bind(this)
    this._onMappingsChange = this._onMappingsChange.bind(this)
    this._onRolesChange = this._onRolesChange.bind(this)
    this._onEnrichmentsUpdate = this._onEnrichmentsUpdate.bind(this)
    this._onTransformationsUpdate = this._onTransformationsUpdate.bind(this)
    this._changeCurrentStepAfterError = this._changeCurrentStepAfterError.bind(
      this
    )
    this._onDatabaseHotChange = this._onDatabaseHotChange.bind(this)
    this._onDatabaseColdChange = this._onDatabaseColdChange.bind(this)
    this._onRetentionPeriodHotChange = this._onRetentionPeriodHotChange.bind(
      this
    )
    this._onRetentionPeriodColdChange = this._onRetentionPeriodColdChange.bind(
      this
    )
    this._onBusinessUnitChange = this._onBusinessUnitChange.bind(this)
    this._onAdditionalInfoChange = this._onAdditionalInfoChange.bind(this)
  }

  get STEPS() {
    return [
      this._formatMessage("new-ingestion-config-step"),
      this._formatMessage("new-ingestion-dict-step"),
      this._formatMessage("new-ingestion-context-step"),
      this._formatMessage("new-ingestion-event-step"),
      this._formatMessage("new-ingestion-mapping-step"),
      this._formatMessage("new-ingestion-others-step"),
    ]
  }

  componentDidMount() {
    const editMode = !!(this.props.ingest && this.props.ingest.alias)
    const dictionaries = (editMode
      ? IngestService.getDictionaries({
          compatibleWithIngestAlias: this.props.ingest.alias,
        })
      : IngestService.getDictionaries()
    ).then(dictionaries =>
      this.setState({
        dictionaries,
      })
    )

    const sites = IngestService.getSites().then(sites =>
      this.setState({ sites })
    )
    const sources = IngestService.getSources().then(sources =>
      this.setState({ sources })
    )
    const prefixes = IngestService.getPrefixes().then(prefixes =>
      this.setState({ prefixes })
    )

    const roles = IngestService.getRoles()
      .then(roles => this.setState({ roles: roles.map(role => role.name) }))
      .catch(_ =>
        callbackRolesErrorMessages(response, "LIST").then(text =>
          message.error(this.props.intl.formatMessage({ id: text }))
        )
      )

    const metadataTables = IngestService.getMetadataTables().then(
      metadataTables => this.setState({ metadataTables })
    )

    const businessUnits = IngestService.getBusinessUnits().then(businessUnits =>
      this.setState({ businessUnits })
    )

    this._setConfirmInformedContext()

    this.setState({ editMode })

    IngestService.getTakenAliases()
      .then(ingestAliases => this.setState({ ingestAliases }))
      .then(_ => {
        return Promise.all([
          dictionaries,
          metadataTables,
          sites,
          sources,
          prefixes,
          roles,
          businessUnits,
        ])
      })
      .then(_ => this.setState({ disabledSteps: false }))
      .catch(_ =>
        message.error(this._formatMessage("new-ingestion-page-load-error"))
      )
  }

  _getDictionary() {
    const { dictionaryId } = this._getIngest()

    if (Array.isArray(this.state.dictionaries) && dictionaryId) {
      return this.state.dictionaries.find(({ id }) => id === dictionaryId)
    } else {
      return null
    }
  }

  _onDatabaseHotChange(hasDatabaseHot) {
    const ingest = this._getIngest()
    _.merge(ingest, { hasDatabaseHot })
    this.ingest = ingest
  }

  _onDatabaseColdChange(hasDatabaseCold) {
    const ingest = this._getIngest()
    _.merge(ingest, { hasDatabaseCold })
    this.ingest = ingest
  }

  _onRetentionPeriodHotChange(retentionPeriodHot) {
    const ingest = this._getIngest()
    _.merge(ingest, { retentionPeriodHot })
    this.ingest = ingest
  }

  _onRetentionPeriodColdChange(retentionPeriodCold) {
    const ingest = this._getIngest()
    _.merge(ingest, { retentionPeriodCold })
    this.ingest = ingest
  }

  _onBusinessUnitChange(businessUnit) {
    const ingest = this._getIngest()
    _.merge(ingest, { businessUnit })
    this.ingest = ingest
  }

  _onAdditionalInfoChange(additionalInfo) {
    const ingest = this._getIngest()
    _.merge(ingest, { additionalInfo })
    this.ingest = ingest
  }

  _onIngestNameChange(name) {
    const ingest = this._getIngest()
    _.merge(ingest, { name })
    this.ingest = ingest
  }

  _onIngestAliasChange(alias) {
    const ingest = this._getIngest()
    _.merge(ingest, { alias })
    this.ingest = ingest
  }

  _onIngestDescriptionChange(description) {
    const ingest = this._getIngest()
    _.merge(ingest, { description })
    this.ingest = ingest
  }

  _onIngestEmailChange(email) {
    const ingest = this._getIngest()
    _.merge(ingest, { email })
    this.ingest = ingest
  }

  _onIngestTagsChange(tags) {
    const ingest = this._getIngest()
    _.merge(ingest, { tags })
    this.ingest = ingest
  }

  _onStepChange(currentStep) {
    this.setState({ currentStep })
  }

  _onRolesChange(roles) {
    const ingest = this._getIngest()
    _.merge(ingest, { roles })
    this.ingest = ingest
  }

  _onNewIngestContextChange(context = {}) {
    const ingest = this._getIngest()
    _.merge(ingest.context, { ...context })
    this.ingest = ingest

    this.setState({ context: _.cloneDeep(this.ingest.context) })
  }

  _onVersionChange(version) {
    const ingest = this._getIngest()
    _.merge(ingest, { version })
    this.ingest = ingest
  }

  _onDictionarySelect(dictionaryId) {
    const ingest = this._getIngest()
    const newDictname = dictionaryId.split(":").shift()
    const oldDictName = ((ingest && ingest.dictionaryId) || "")
      .split(":")
      .shift()

    if (newDictname !== oldDictName) {
      delete ingest.mappings
      delete ingest.enrichments
      delete ingest.transformations

      _.merge(ingest, {
        mappings: [],
        enrichments: [],
        transformations: [],
      })

      this.setState({ mappings: [], enrichments: [], transformations: [] })
    }

    this.ingest = Object.assign({}, ingest, { dictionaryId })
  }

  _onEventChange(event) {
    const ingest = this._getIngest()
    const headersOrder =
      event.event === null || event.event === undefined
        ? []
        : Object.keys(event.event)

    if (ingest.event) {
      delete ingest.event
    }

    delete ingest.headersOrder

    if (!_.isEqual(event, ingest.event)) {
      delete ingest.mappings
      delete ingest.enrichments
      delete ingest.transformations

      _.merge(ingest, {
        mappings: [],
        enrichments: [],
        transformations: [],
      })
    }

    _.merge(ingest, { event, headersOrder })

    this.ingest = ingest

    this.setState({
      headersOrder,
      event,
      mappings: [],
      enrichments: [],
      transformations: [],
    })
  }

  _onSeparatorChange(separator) {
    const ingest = this._getIngest()

    _.merge(ingest, { separator })

    this.ingest = ingest
  }

  _onHeadersProvidedSeparatelyChange(headersProvidedSeparately) {
    const ingest = this._getIngest()

    _.merge(ingest, { headersProvidedSeparately })

    this.ingest = ingest

    this.setState({ headersProvidedSeparately })
  }

  _getIngest() {
    const ingest = {}

    return _.merge(ingest, this.props.ingest || {}, this.ingest || {})
  }

  _onMappingsChange(mappings) {
    const ingest = this._getIngest()
    const destinations = Array.from(new Set(mappings.map(m => m.destination)))

    const enrichments = (ingest.enrichments || []).filter(({ destination }) => {
      return !destinations.includes(destination)
    })
    const transformations = (ingest.transformations || []).filter(
      ({ destination }) => {
        return !destinations.includes(destination)
      }
    )

    delete ingest.mappings
    delete ingest.enrichments
    delete ingest.transformations

    _.merge(ingest, { mappings, enrichments, transformations })

    this.ingest = ingest

    this.setState({ mappings, enrichments, transformations })
  }

  _onEnrichmentsUpdate(enrichments) {
    const ingest = this._getIngest()

    delete ingest.enrichments

    _.merge(ingest, { enrichments })

    this.ingest = ingest

    this.setState({ enrichments })
  }

  _onTransformationsUpdate(transformations) {
    const ingest = this._getIngest()

    delete ingest.transformations

    _.merge(ingest, { transformations })

    this.ingest = ingest

    this.setState({ transformations })
  }

  _setConfirmInformedContext() {
    const ingest = this._getIngest()
    const { context = {} } = ingest

    context.confirmInformedContext =
      (context && context.option) === INFORMED_OPTION

    _.merge(ingest, { context })

    this.ingest = ingest
  }

  _goToNextStep(currentStep) {
    return () => {
      ++currentStep

      this.setState({ currentStep })
    }
  }

  _changeCurrentStepAfterError(step) {
    this.setState({ currentStep: step })
  }

  _getStepDisabled(step) {
    if (this.state.editMode) {
      return this.state.disabledSteps
    } else {
      return step > this.state.currentStep
    }
  }

  _renderCurrentStep() {
    const dictionary = this._getDictionary()
    const ingest = this._getIngest()
    const event = this.state.event || (ingest && ingest.event)
    let eventRepresentation = null
    if (event) {
      eventRepresentation = Array.isArray(event.event)
        ? event.event.shift()
        : event.event
    }
    if (eventRepresentation) {
      eventRepresentation = ["CSV", "JSONL"].includes(event.fileType)
        ? [eventRepresentation]
        : eventRepresentation
    }

    switch (this.state.currentStep) {
      case 0:
        return (
          <Col span={24}>
            <IngestDataForm
              editing={this.state.editMode}
              type={INGEST_TYPES["batch"]}
              disableAlias={this.props.ingest && this.props.ingest.alias}
              id={ingest && ingest.id}
              name={ingest && ingest.name}
              alias={ingest && ingest.alias}
              description={ingest && ingest.description}
              version={ingest && ingest.version}
              versions={
                ingest &&
                Array.isArray(ingest.versions) &&
                ingest.versions.map(({ version }) => version)
              }
              email={ingest && ingest.email}
              tags={ingest && ingest.tags}
              combinedIngest={ingest && ingest.linked_with}
              takenAliases={this.state.ingestAliases}
              roles={this.state.roles}
              rolesSelected={ingest && ingest.roles ? ingest.roles : []}
              onNameChange={this._onIngestNameChange}
              onAliasChange={this._onIngestAliasChange}
              onVersionChange={this._onVersionChange}
              onDescriptionChange={this._onIngestDescriptionChange}
              onEmailChange={this._onIngestEmailChange}
              onTagsUpdate={this._onIngestTagsChange}
              onRolesChange={this._onRolesChange}
              onNextStep={this._goToNextStep(0)}
              hasDatabaseHot={ingest && ingest.hasDatabaseHot}
              hasDatabaseCold={ingest && ingest.hasDatabaseCold}
              retentionPeriodHot={ingest && ingest.retentionPeriodHot}
              retentionPeriodCold={ingest && ingest.retentionPeriodCold}
              onDatabaseHotChange={this._onDatabaseHotChange}
              onDatabaseColdChange={this._onDatabaseColdChange}
              onRetentionPeriodHotChange={this._onRetentionPeriodHotChange}
              onRetentionPeriodColdChange={this._onRetentionPeriodColdChange}
              businessUnits={this.state.businessUnits}
              onBusinessUnitChange={this._onBusinessUnitChange}
              businessUnit={ingest && ingest.businessUnit}
              onAdditionalInfoChange={this._onAdditionalInfoChange}
              additionalInfo={ingest && ingest.additionalInfo}
            />
          </Col>
        )
      case 1:
        return (
          <Col span={16}>
            {Array.isArray(this.state.dictionaries) ? (
              <IngestDictionaryForm
                combinedIngest={ingest && ingest.linked_with}
                dictionaries={this.state.dictionaries}
                selectedDictionaryId={dictionary && dictionary.id}
                onDictionarySelect={this._onDictionarySelect}
                onNextStep={this._goToNextStep(1)}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin size="large" />
              </div>
            )}
          </Col>
        )
      case 2:
        const context = this.state.context || (ingest && ingest.context)
        let selectedTab = 1

        switch (context.option) {
          case FIXED_OPTION:
            selectedTab = 1
            break
          case FILTERED_OPTION:
            selectedTab = 2
            break
          case INFORMED_OPTION:
            selectedTab = 3
            break
          default:
            selectedTab = 1
        }

        return (
          <Col span={16}>
            <IngestContextSelector
              sites={this.state.sites}
              sources={this.state.sources}
              prefixes={this.state.prefixes}
              site={context.site}
              source={context.source}
              prefix={context.prefix}
              delimiter={context.delimiter}
              fieldName={context.fieldName}
              option={context.option}
              dictionaryFields={dictionary && dictionary.fields}
              selectedTab={selectedTab}
              onContextChange={this._onNewIngestContextChange}
              onNextStep={this._goToNextStep(2)}
            />
          </Col>
        )
      case 3:
        const { headersOrder } = this.state
        const ingestHeadersOrder = ingest && ingest.headersOrder

        return (
          <Col span={16}>
            <IngestEventForm
              type={INGEST_TYPES["batch"]}
              event={event}
              separator={ingest && ingest.separator}
              headersOrder={
                headersOrder === undefined ? ingestHeadersOrder : headersOrder
              }
              headersProvidedSeparately={
                this.state.headersProvidedSeparately === null
                  ? ingest && ingest.headersProvidedSeparately
                  : this.state.headersProvidedSeparately
              }
              onEventChange={this._onEventChange}
              onSeparatorChange={this._onSeparatorChange}
              onHeadersProvidedSeparatelyChange={
                this._onHeadersProvidedSeparatelyChange
              }
              onNextStep={this._goToNextStep(3)}
            />
          </Col>
        )
      case 4:
        return (
          <IngestMappings
            dictionaryFields={dictionary && dictionary.fields}
            event={eventRepresentation}
            mappings={this.state.mappings || (ingest && ingest.mappings)}
            enrichments={
              this.state.enrichments || (ingest && ingest.enrichments)
            }
            transformations={
              this.state.transformations || (ingest && ingest.transformations)
            }
            onNextStep={this._goToNextStep(4)}
            onMappingsChange={this._onMappingsChange}
            onEnrichmentsUpdate={this._onEnrichmentsUpdate}
            onTransformationsUpdate={this._onTransformationsUpdate}
          />
        )
      case 5:
        return (
          <IngestOthers
            metadataTables={this.state.metadataTables}
            dictionaryFields={dictionary && dictionary.fields}
            event={eventRepresentation}
            mappings={this.state.mappings || (ingest && ingest.mappings)}
            enrichments={
              this.state.enrichments || (ingest && ingest.enrichments)
            }
            transformations={
              this.state.transformations || (ingest && ingest.transformations)
            }
            onEnrichmentsUpdate={this._onEnrichmentsUpdate}
            onTransformationsUpdate={this._onTransformationsUpdate}
          />
        )
      default:
        return null
    }
  }

  _getStepTitle(step, title) {
    return step === this.state.currentStep ? (
      <div style={{ color: "#5731F9" }}>{title}</div>
    ) : (
      title
    )
  }

  _getStepDescription(step) {
    return step === this.state.currentStep ? (
      <div style={{ borderBottom: "1px solid #27D098", width: 20 }} />
    ) : null
  }

  render() {
    const ingest = this._getIngest()

    return (
      <Form className="formCustom">
        <div className="content">
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              <Steps
                size="small"
                current={this.state.currentStep}
                direction="horizontal"
                onChange={this._onStepChange}
              >
                {this.STEPS.map((title, index) => (
                  <Step
                    disabled={this._getStepDisabled(index)}
                    key={title}
                    title={this._getStepTitle(index, title)}
                    description={this._getStepDescription(index)}
                  />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: MARGIN,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this._renderCurrentStep()}
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <IngestActivate
              ingest={ingest}
              goToStep={this._changeCurrentStepAfterError}
            />
          </Col>
        </Row>
      </Form>
    )
  }
}

export default injectIntl(BatchIngestForm)
