import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd"
import ModalTable from "../../components/modalTable"
import { regroupDictionariesWithVersions } from "../../services/dictionary/dictionary"
import eyeIcon from "../../assets/images/ic_eye.png"

const { Option } = Select

class IngestDictionaryForm extends React.Component {
  static _computeDictionaries(dictionaries, selectedDictionaryId) {
    const selectedDictionary = IngestDictionaryForm._getSelectedDictionaryById(
      selectedDictionaryId,
      dictionaries
    )
    const regroupedDictionaries = regroupDictionariesWithVersions(dictionaries)

    if (selectedDictionary) {
      const dictToUpdate = regroupedDictionaries.find(
        ({ name }) => name === selectedDictionary.name
      )

      dictToUpdate.id = selectedDictionary.id
      dictToUpdate.version = selectedDictionary.version
      dictToUpdate.fields = selectedDictionary.fields
    }

    return {
      dictionaries: regroupedDictionaries,
      selectedDictionary,
    }
  }

  static _getSelectedDictionaryById(id, dictionaries) {
    if (id && dictionaries && dictionaries.length > 0) {
      return dictionaries.find(dict => dict.id === id)
    } else {
      return null
    }
  }

  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      dictionaryFilter: "",
      dictionaries: null,
      selectedDictionary: null,
      modalVisible: false,
      selectedDictionaryToViewDetail: null,
    }

    this._getDictionariesToRender = this._getDictionariesToRender.bind(this)
    this._onDictionaryDetailClick = this._onDictionaryDetailClick.bind(this)
    this._onDictionaryVersionSelect = this._onDictionaryVersionSelect.bind(this)
    this._onDictionarySelect = this._onDictionarySelect.bind(this)
    this._onDictionaryFilterInput = this._onDictionaryFilterInput.bind(this)
    this._displaySelectedDictionaryLabel = this._displaySelectedDictionaryLabel.bind(
      this
    )
    this._isCompatible = this._isCompatible.bind(this)
    this.nextStep = this.nextStep.bind(this)
  }

  componentDidMount() {
    const {
      dictionaries,
      selectedDictionary,
    } = IngestDictionaryForm._computeDictionaries(
      this.props.dictionaries,
      this.props.selectedDictionaryId
    )

    this.setState({ dictionaries, selectedDictionary })
  }

  get MODAL_COLUMNS() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-type"),
        dataIndex: "type",
        key: "type",
      },
      {
        title: this._formatMessage(
          "new-ingestion-tables-column-header-property"
        ),
        dataIndex: "property",
        key: "property",
      },
    ]
  }

  _onDictionaryVersionSelect(option, dictionaryName) {
    const dictionaries = this.state.dictionaries.slice()
    const index = dictionaries.findIndex(dict => dict.name === dictionaryName)
    const dictionary = dictionaries[index]
    const selectedDictionaryVersion =
      Array.isArray(dictionary.versions) &&
      dictionary.versions.find(({ version }) => version === option)

    dictionaries[index] = Object.assign(
      {},
      { ...dictionary },
      {
        id: selectedDictionaryVersion.id,
        version: selectedDictionaryVersion.version,
        fields: selectedDictionaryVersion.fields.slice(),
      }
    )

    this.setState(
      {
        dictionaries,
        selectedDictionary: dictionaries[index],
      },
      () => {
        typeof this.props.onDictionarySelect === "function" &&
          this.props.onDictionarySelect(dictionaries[index].id)
      }
    )
  }

  _onDictionarySelect(selectedDictionary) {
    if (selectedDictionary && selectedDictionary.length > 0) {
      const dict = this.state.dictionaries.find(
        ({ name }) => name === selectedDictionary[0]
      )

      this.setState({ selectedDictionary: dict }, () => {
        typeof this.props.onDictionarySelect === "function" &&
          this.props.onDictionarySelect(dict.id)
      })
    }
  }

  nextStep() {
    typeof this.props.onNextStep === "function" && this.props.onNextStep()
  }

  _onDictionaryDetailClick(dictionary) {
    if (dictionary && dictionary.fields && dictionary.fields.length > 0) {
      this.setState({ selectedDictionaryToViewDetail: dictionary }, () =>
        this.setState({ modalVisible: true })
      )
    }
  }

  _onDictionaryFilterInput(ev) {
    this.setState({
      dictionaryFilter:
        (ev && ev.currentTarget && ev.currentTarget.value) || "",
    })
  }

  _getDictionariesToRender(dictionaries, filter = "") {
    return filter
      ? (dictionaries || []).filter(({ name = "" }) =>
          name.toLowerCase().includes(filter.toLowerCase())
        )
      : (dictionaries || []).slice()
  }

  _isCompatible(dictionary) {
    const compatible = dictionary.compatible

    return compatible === "1" || compatible === 1 || compatible === true
  }

  getRowSelection() {
    const selectedRowKeys =
      this.state.selectedDictionary && this.state.selectedDictionary.name
        ? [this.state.selectedDictionary.name]
        : []

    return {
      selectedRowKeys,
      type: "radio",
      onChange: this._onDictionarySelect,
      getCheckboxProps: record => ({
        disabled: this.props.editing && !this._isCompatible(record),
      }),
    }
  }

  _displaySelectedDictionaryLabel() {
    if (!this.state.selectedDictionary) {
      return ""
    } else {
      return `${this.state.selectedDictionary.name}${
        this.state.selectedDictionary.version
          ? `:${this.state.selectedDictionary.version}`
          : ""
      } `
    }
  }

  render() {
    this.columnsDict = [
      {
        title: this._formatMessage("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("version"),
        dataIndex: "version",
        key: "version",
        width: 500,
        render: (versionName, dictionary) =>
          this.props.combinedIngest ? (
            <div>{dictionary.version}</div>
          ) : Array.isArray(dictionary.versions) &&
            dictionary.versions.length ? (
            <Select
              name="property"
              key={`select-${dictionary.name}:${dictionary.version}`}
              defaultValue={dictionary.version}
              style={{ width: "75%", color: "grey" }}
              onSelect={option =>
                this._onDictionaryVersionSelect(option, dictionary.name)
              }
            >
              {dictionary.versions.map(version => (
                <Option
                  key={`option-${version.name}:${version.version}`}
                  value={version.version}
                  disabled={this.props.editing && !this._isCompatible(version)}
                >
                  {version.version}
                </Option>
              ))}
            </Select>
          ) : null,
      },
      {
        title: this._formatMessage("created-at"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 300,
        sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        sortDirections: ["ascend", "descend"],
        render: createdAt => new Date(createdAt).toISOString(),
      },
      {
        title: this._formatMessage("actions"),
        dataIndex: "",
        key: "id",
        width: 120,
        align: "center",
        render: (text, record) => (
          <div>
            <Button
              type="link"
              style={{ paddingLeft: 0 }}
              onClick={_ => this._onDictionaryDetailClick(record)}
            >
              <img
                style={{ fontSize: "1.25rem" }}
                className="ingest-actions__button__delete"
                src={eyeIcon}
                alt="eyeIcon"
              />
            </Button>
          </div>
        ),
        ellipsis: true,
      },
    ]

    return (
      <>
        <Row style={{ marginBottom: 25 }}>
          {this.props.combinedIngest ? (
            <Col span={24}>
              <h5>
                {this._formatMessage(
                  "page-new-ingestion-combined-ingestion-intro"
                )}
                :
              </h5>
              <Table
                size="small"
                rowKey={item => item.name}
                rowSelection={this.getRowSelection()}
                pagination={false}
                columns={this.columnsDict}
                loading={
                  this.state.selectedDictionary === null ||
                  this.state.selectedDictionary === undefined
                }
                dataSource={
                  this.state.selectedDictionary === null ||
                  this.state.selectedDictionary === undefined
                    ? []
                    : [this.state.selectedDictionary]
                }
                locale={{
                  emptyText: this.props.intl.formatMessage({ id: "no-data" }),
                }}
              />
            </Col>
          ) : (
            <>
              <Col span={12}>
                <Form.Item
                  label={this._formatMessage("dictionary-select-text")}
                  layout="horizontal"
                >
                  <Input
                    required
                    name="dictionary"
                    onChange={this._onDictionaryFilterInput}
                    placeholder={this._formatMessage(
                      "dictionary-filter-placeholder-text"
                    )}
                    suffix={
                      <Tooltip
                        title={this._formatMessage(
                          "dictionary-tooltip-final-format"
                        )}
                      >
                        <Icon
                          type="info-circle"
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </Col>
              {this.state.selectedDictionary &&
              Object.keys(this.state.selectedDictionary).length > 0 ? (
                <Col span={10} offset={1} className="clickable">
                  <span className="customTag">
                    {" "}
                    {this._formatMessage(
                      "new-ingestion-dictionary-step-dictionary-selected"
                    )}
                    :<b>{this._displaySelectedDictionaryLabel()}</b>
                  </span>
                </Col>
              ) : null}
              <Col span={24}>
                <Table
                  size="small"
                  rowKey={item => item.name}
                  rowSelection={this.getRowSelection()}
                  pagination={{ defaultPageSize: 5 }}
                  columns={this.columnsDict}
                  dataSource={this._getDictionariesToRender(
                    this.state.dictionaries,
                    this.state.dictionaryFilter
                  )}
                  loading={this.state.dictionaries === null}
                  locale={{
                    emptyText: this.props.intl.formatMessage({ id: "no-data" }),
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Button
              disabled={!this.state.selectedDictionary}
              type="primary"
              onClick={this.nextStep}
            >
              {this._formatMessage("next-step")}
            </Button>
          </Col>
        </Row>
        <ModalTable
          title={this._formatMessage(
            "page-new-ingestion-dictionary-detail-modal-title"
          )}
          modalVisible={this.state.modalVisible}
          dataId={
            this.state.selectedDictionaryToViewDetail &&
            this.state.selectedDictionaryToViewDetail.id
          }
          data={
            this.state.selectedDictionaryToViewDetail &&
            this.state.selectedDictionaryToViewDetail.fields
          }
          fields={
            this.state.selectedDictionaryToViewDetail &&
            this.state.selectedDictionaryToViewDetail.fields
          }
          tableColumns={this.MODAL_COLUMNS}
          onHideModal={() => this.setState({ modalVisible: false })}
        />
      </>
    )
  }
}

export default injectIntl(IngestDictionaryForm)
