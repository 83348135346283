const isValidEmail = value => {
  const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return value ? validEmailRegex.test(String(value).toLowerCase()) : true
}

const emailUtils = {
  isValidEmail,
}

export default emailUtils
