/**
 * Helper to regroup dictionaries with versions
 */
export function regroupDictionariesWithVersions(dictionaries) {
  const result = []

  if (Array.isArray(dictionaries) && dictionaries.length > 0) {
    const regroup = dictionaries.reduce(function(r, a) {
      r[a.name] = r[a.name] || []
      r[a.name].push(a)

      return r
    }, Object.create(null))
    Object.keys(regroup).forEach(key => {
      const versions = regroup[key].sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      )
      const lastVersionCompatible = _getLastVersionCompatible(versions)

      result.push({
        id: lastVersionCompatible ? lastVersionCompatible.id : null,
        name: key,
        version: lastVersionCompatible ? lastVersionCompatible.version : null,
        createdAt: lastVersionCompatible
          ? lastVersionCompatible.createdAt
          : null,
        fields: lastVersionCompatible ? lastVersionCompatible.fields : null,
        countVersions: versions.length,
        versions: versions,
        compatible: lastVersionCompatible
          ? _isCompatible(lastVersionCompatible.compatible)
          : true,
        dateToShow: lastVersionCompatible
          ? lastVersionCompatible.createdAt
          : null,
      })
    })
  }
  return result
}

function _getLastVersionCompatible(versions) {
  let lastVersionCompatible = versions[0]

  versions.forEach(version => {
    if (version.compatible === 1 && !lastVersionCompatible) {
      lastVersionCompatible = version
    }
  })

  return lastVersionCompatible
}

function _isCompatible(compatible) {
  return compatible === 1
}
