import React, { useEffect } from "react"
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Select,
} from "antd"
import { useIntl } from "gatsby-plugin-intl"

import EditableTagGroup from "../editableTagGroup"
import LabelWithTooltip from "../labelWithTooltip"
import { INGEST_TYPES } from "../../services/constants"
import useStateObject from "../../hooks/useStateObject"
import emailUtils from "../../utils/email-utils"
import retentionPeriodLayers from "../../assets/images/ingests/retention_period_layers.png"

const LAYOUT = "horizontal"
const ALIAS_MAX_LENGTH = 60

const { TextArea } = Input
const { Option } = Select

const RETENTION_PERIOD = {
  ONE_DAY: "P1D",
  ONE_WEEK: "P7D",
  FIFTEEN_DAYS: "P15D",
  ONE_MONTH: "P1M",
  THREE_MONTHS: "P3M",
  SIX_MONTHS: "P6M",
  ONE_YEAR: "P1Y",
  THREE_YEARS: "P3Y",
  FIVE_YEARS: "P5Y",
  TEN_YEARS: "P10Y",
  UNLIMITED: "unlimited",
}

const IngestDataForm = ({
  form,
  editing,
  name,
  alias,
  email,
  takenAliases,
  disableAlias,
  version,
  versions,
  description,
  combinedIngest,
  tags,
  type,
  roles,
  rolesSelected,
  hasDatabaseHot,
  retentionPeriodHot,
  hasDatabaseCold,
  retentionPeriodCold,
  businessUnit,
  businessUnits,
  onNameChange,
  onAliasChange,
  onVersionChange,
  onDescriptionChange,
  onEmailChange,
  onTagsUpdate,
  onRolesChange,
  onDatabaseHotChange,
  onRetentionPeriodHotChange,
  onDatabaseColdChange,
  onRetentionPeriodColdChange,
  onBusinessUnitChange,
  onAdditionalInfoChange,
  onNextStep,
  additionalInfo,
}) => {
  const intl = useIntl()
  const { getFieldDecorator, setFieldsValue } = form
  const getEmailValues = value => {
    if (value) {
      if (Array.isArray(value) && value.length) {
        return value
      } else if (typeof value === "string") {
        return value.split(",")
      }
    }
    return []
  }
  const [state, setState] = useStateObject({
    roles: [],
    rolesSelected: [],
    hasDatabaseHot: hasDatabaseHot || false,
    hasDatabaseCold: hasDatabaseCold || false,
    email: getEmailValues(email),
  })
  useEffect(() => {
    setFieldsValue({
      retentionPeriodHot,
      retentionPeriodCold,
      businessUnit,
    })
    if (editing === true) {
      form.validateFields()
    }
  }, [])
  useEffect(() => {
    setState({ roles, rolesSelected })
  }, [roles, rolesSelected])
  const formatMessage = (id, parametrized) =>
    intl.formatMessage({ id }, { ...parametrized })
  const HOT_RETENTION_PERIODS = [
    {
      value: RETENTION_PERIOD.ONE_DAY,
      label: intl.formatMessage({
        id: "retention-period-one-day",
      }),
    },
    {
      value: RETENTION_PERIOD.ONE_WEEK,
      label: intl.formatMessage({
        id: "retention-period-one-week",
      }),
    },
    {
      value: RETENTION_PERIOD.FIFTEEN_DAYS,
      label: intl.formatMessage({
        id: "retention-period-fifteen-days",
      }),
    },
    {
      value: RETENTION_PERIOD.ONE_MONTH,
      label: intl.formatMessage({
        id: "retention-period-one-month",
      }),
    },
    {
      value: RETENTION_PERIOD.THREE_MONTHS,
      label: intl.formatMessage({
        id: "retention-period-three-months",
      }),
    },
    {
      value: RETENTION_PERIOD.SIX_MONTHS,
      label: intl.formatMessage({
        id: "retention-period-six-months",
      }),
    },
    {
      value: RETENTION_PERIOD.ONE_YEAR,
      label: intl.formatMessage({
        id: "retention-period-one-year",
      }),
    },
    {
      value: RETENTION_PERIOD.THREE_YEARS,
      label: intl.formatMessage({
        id: "retention-period-three-years",
      }),
    },
  ]
  const COLD_RETENTION_PERIODS = [
    {
      value: RETENTION_PERIOD.ONE_MONTH,
      label: intl.formatMessage({
        id: "retention-period-one-month",
      }),
    },
    {
      value: RETENTION_PERIOD.THREE_MONTHS,
      label: intl.formatMessage({
        id: "retention-period-three-months",
      }),
    },
    {
      value: RETENTION_PERIOD.SIX_MONTHS,
      label: intl.formatMessage({
        id: "retention-period-six-months",
      }),
    },
    {
      value: RETENTION_PERIOD.ONE_YEAR,
      label: intl.formatMessage({
        id: "retention-period-one-year",
      }),
    },
    {
      value: RETENTION_PERIOD.THREE_YEARS,
      label: intl.formatMessage({
        id: "retention-period-three-years",
      }),
    },
    {
      value: RETENTION_PERIOD.FIVE_YEARS,
      label: intl.formatMessage({
        id: "retention-period-five-years",
      }),
    },
    {
      value: RETENTION_PERIOD.TEN_YEARS,
      label: intl.formatMessage({
        id: "retention-period-ten-years",
      }),
    },
    {
      value: RETENTION_PERIOD.UNLIMITED,
      label: intl.formatMessage({
        id: "retention-period-unlimited",
      }),
    },
  ]

  const _onNameChange = ev => {
    onNameChange(ev.currentTarget.value)
  }

  const _onAliasChange = ev => {
    onAliasChange(ev.currentTarget.value)
  }

  const _onVersionChange = ev => {
    onVersionChange(ev.currentTarget.value)
  }

  const _onDescriptionChange = ev => {
    onDescriptionChange(ev.currentTarget.value)
  }

  const _onEmailChange = value => {
    setState({ email: value })
    onEmailChange(value)
  }

  const _onTagsUpdate = values => {
    onTagsUpdate(values)
  }

  const _onRolesChange = values => {
    onRolesChange(values)
  }

  const _onChangeRole = rolesSelected => {
    setState({ rolesSelected })
    _onRolesChange(rolesSelected)
  }

  const _onDatabaseHotCheckboxChange = ev => {
    const {
      target: { checked },
    } = ev
    onDatabaseHotChange(checked)
    const state = { hasDatabaseHot: checked }
    if (!checked) {
      onRetentionPeriodHotChange(null)
      setFieldsValue({ retentionPeriodHot: null })
    }
    setState(state)
  }

  const _onDatabaseColdCheckboxChange = ev => {
    const {
      target: { checked },
    } = ev
    onDatabaseColdChange(checked)
    const state = { hasDatabaseCold: checked }
    if (!checked) {
      onRetentionPeriodColdChange(null)
      setFieldsValue({ retentionPeriodCold: null })
    }
    setState(state)
  }

  const _onRetentionPeriodHotChange = value => {
    onRetentionPeriodHotChange(value)
    setFieldsValue({ retentionPeriodHot: value })
  }

  const _onRetentionPeriodColdChange = value => {
    onRetentionPeriodColdChange(value)
    setFieldsValue({ retentionPeriodCold: value })
  }

  const _onBusinessUnitChange = value => {
    onBusinessUnitChange(value)
    setState({ businessUnit: value })
  }

  const _onAdditionalInfoChange = ev => {
    onAdditionalInfoChange(ev.currentTarget.value)
  }

  const _onSelectRole = value => {
    let rolesSelected = []
    if (value === "select-all") {
      rolesSelected = state.roles
      setState({ rolesSelected }, () => _onRolesChange(rolesSelected))
    } else if (value === "clear") {
      setState({ rolesSelected }, () => _onRolesChange(rolesSelected))
    }
  }

  const _nextStep = () => {
    form.validateFields(err => {
      if (!err) {
        onNextStep()
      }
    })
  }

  const renderCommonFields = () => {
    return (
      <>
        <Row>
          <Form.Item label={formatMessage("name")} layout={LAYOUT}>
            {getFieldDecorator("name", {
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: formatMessage("new-ingestion-name-required"),
                },
              ],
            })(
              <Input
                name="name"
                onChange={_onNameChange}
                placeholder={formatMessage("new-ingestion-name-placeholder")}
              />
            )}
          </Form.Item>
        </Row>
        <Row>
          <Col span={15}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={formatMessage("new-ingestion-alias-label")}
                  tooltip={formatMessage("new-ingestion-alias-tooltip")}
                />
              }
              layout={LAYOUT}
            >
              {getFieldDecorator("alias", {
                initialValue: alias,
                rules: [
                  {
                    required: true,
                    message: formatMessage("new-ingestion-alias-required"),
                  },
                  {
                    max: ALIAS_MAX_LENGTH,
                    message: formatMessage("new-ingestion-alias-max-length"),
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (!editing) {
                        try {
                          if (/[`~,.<>;':"/[\]|{}()=_+\s]/g.test(value)) {
                            throw new Error(
                              formatMessage("new-ingestion-alias-regex-advise")
                            )
                          } else if ((takenAliases || []).includes(value)) {
                            throw new Error(
                              formatMessage(
                                "new-ingestion-alias-already-exists"
                              )
                            )
                          } else {
                            cb()
                          }
                        } catch (err) {
                          cb(err)
                        }
                      } else {
                        cb()
                      }
                    },
                  },
                ],
              })(
                <Input
                  name="alias"
                  enterButton={false}
                  disabled={disableAlias || !takenAliases}
                  onChange={_onAliasChange}
                  placeholder={
                    takenAliases
                      ? formatMessage("new-ingestion-alias-placeholder")
                      : formatMessage("new-ingestion-alias-loading")
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item
              label={formatMessage("new-ingestion-version-label")}
              layout={LAYOUT}
            >
              {getFieldDecorator("version", {
                initialValue: version,
                rules: [
                  {
                    required: true,
                    message: formatMessage("new-ingestion-version-required"),
                  },
                  {
                    max: ALIAS_MAX_LENGTH,
                    message: formatMessage("new-ingestion-version-max-length"),
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value) {
                        try {
                          if ((versions || []).includes(value)) {
                            throw new Error(
                              formatMessage(
                                "new-ingestion-version-already-exists"
                              )
                            )
                          } else {
                            cb()
                          }
                        } catch (err) {
                          cb(err)
                        }
                      } else {
                        cb()
                      }
                    },
                  },
                ],
              })(
                <Input
                  name="version"
                  onChange={_onVersionChange}
                  placeholder={formatMessage(
                    "new-ingestion-version-placeholder"
                  )}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              label={formatMessage("new-ingestion-description-label")}
              layout={LAYOUT}
            >
              {getFieldDecorator("ingestDescription", {
                initialValue: description,
                rules: [
                  {
                    required: true,
                    message: formatMessage(
                      "new-ingestion-description-required"
                    ),
                  },
                ],
              })(
                <TextArea
                  name="description"
                  autoSize={true}
                  onChange={_onDescriptionChange}
                  placeholder={formatMessage(
                    "new-ingestion-description-placeholder"
                  )}
                  style={{ minHeight: "5.6em" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={formatMessage("new-ingestion-business-unit")}
                  tooltip={formatMessage("new-ingestion-business-unit-tooltip")}
                />
              }
              layout="horizontal"
            >
              {getFieldDecorator("businessUnit", {
                initialValue: state.businessUnit,
                rules: [
                  {
                    required: true,
                    message: formatMessage(
                      "new-ingestion-business-unit-required"
                    ),
                  },
                ],
              })(
                <Select
                  name="business-unit"
                  aria-label="business-unit"
                  onChange={_onBusinessUnitChange}
                  style={{ width: "100%" }}
                  value={state.businessUnit}
                >
                  {businessUnits.map(p => (
                    <Option key={p.value} value={p.value}>
                      {p.text}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  const renderRolesForm = () => {
    return (
      <Row>
        <Col span={24}>{formatMessage("new-ingestion-roles-intro")}</Col>
        <Col span={24} style={{ marginTop: 5 }}>
          <Form.Item>
            <Select
              showSearch={true}
              mode="multiple"
              placeholder={formatMessage("new-ingestion-roles-placeholder")}
              value={state.rolesSelected}
              loading={state.roles && state.roles.length === 0}
              name="select-ingest"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={_onChangeRole}
              onSelect={_onSelectRole}
            >
              <Option key="select-all" value="select-all">
                {formatMessage("new-ingestion-roles-select-all-option")}
              </Option>
              <Option key="clear" value="clear">
                {formatMessage("new-ingestion-roles-clear-all-option")}
              </Option>
              {Array.isArray(state.roles)
                ? state.roles.map(d => (
                    <Option key={d} value={d}>
                      {d}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    )
  }

  const renderCombinedIngestForm = () => {
    return combinedIngest ? (
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item
              label={formatMessage("page-ingestions-ingestion-combined-label")}
            >
              {combinedIngest}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    ) : null
  }

  const renderTags = () => {
    const initialValues = Array.isArray(tags) && tags.length ? tags : [type]
    return (
      <Row>
        <Col span={24} style={{ display: "flex" }}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <div style={{ marginRight: 15 }}>
                {" "}
                {formatMessage("new-ingestion-tags-label")}
                {":"}
              </div>
              <EditableTagGroup
                name="tags"
                initialValues={initialValues}
                updateParent={_onTagsUpdate}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
    )
  }

  const renderNextStep = () => {
    return (
      <Row style={{ marginTop: 30 }}>
        <Col span={24}>
          <Button type="primary" onClick={_nextStep}>
            {formatMessage("next-step")}
          </Button>
        </Col>
      </Row>
    )
  }

  const renderRetentionPeriodsForm = () => {
    return (
      <Form>
        <Row>
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <h3 style={{ marginRight: "10px" }}>
                {formatMessage("new-ingestion-database-and-retention-period")}
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={formatMessage("new-ingestion-database-types")}
                  tooltip={
                    <div style={{ backgroundColor: "white", padding: 10 }}>
                      <img
                        src={retentionPeriodLayers}
                        alt="retentionPeriodLayers"
                        height={308}
                      />
                    </div>
                  }
                  tooltipWidth={1000}
                />
              }
              layout={LAYOUT}
            >
              <Row>
                <Col span={12}>
                  <Checkbox
                    id="new-ingestion-database-hot"
                    value={state.hasDatabaseHot}
                    checked={state.hasDatabaseHot}
                    onChange={_onDatabaseHotCheckboxChange}
                  >
                    {formatMessage("new-ingestion-database-hot")}
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox
                    id="new-ingestion-database-cold"
                    value={state.hasDatabaseCold}
                    checked={state.hasDatabaseCold}
                    onChange={_onDatabaseColdCheckboxChange}
                  >
                    {formatMessage("new-ingestion-database-cold")}
                  </Checkbox>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={formatMessage("new-ingestion-retention-period")}
                  tooltip={formatMessage(
                    "new-ingestion-retention-period-hot-tooltip"
                  )}
                />
              }
              layout="horizontal"
            >
              {getFieldDecorator("retentionPeriodHot", {
                rules: [
                  {
                    required: true,
                    message: formatMessage(
                      "new-ingestion-retention-period-hot-required"
                    ),
                  },
                ],
              })(
                <Select
                  name="retention-period-hot"
                  aria-label="retention-period-hot"
                  onChange={_onRetentionPeriodHotChange}
                  style={{ width: "96%" }}
                  disabled={!state.hasDatabaseHot}
                >
                  {HOT_RETENTION_PERIODS.map(p => (
                    <Option key={p.value} value={p.value}>
                      {p.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={formatMessage("new-ingestion-retention-period")}
                  tooltip={formatMessage(
                    "new-ingestion-retention-period-cold-tooltip"
                  )}
                />
              }
              layout="horizontal"
            >
              {getFieldDecorator("retentionPeriodCold", {
                rules: [
                  {
                    required: true,
                    message: formatMessage(
                      "new-ingestion-retention-period-cold-required"
                    ),
                  },
                ],
              })(
                <Select
                  name="retention-period-cold"
                  aria-label="retention-period-cold"
                  onChange={_onRetentionPeriodColdChange}
                  style={{ width: "96%" }}
                  disabled={!state.hasDatabaseCold}
                >
                  {COLD_RETENTION_PERIODS.map(p => (
                    <Option key={p.value} value={p.value}>
                      {p.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              label={formatMessage("new-ingestion-additional-info-label")}
              layout={LAYOUT}
            >
              {getFieldDecorator("ingestAdditionalInfo", {
                initialValue: additionalInfo,
              })(
                <TextArea
                  name="additionalInfo"
                  autoSize={true}
                  onChange={_onAdditionalInfoChange}
                  placeholder={formatMessage(
                    "new-ingestion-additional-info-placeholder"
                  )}
                  style={{ minHeight: "5.6em" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderRtNrtForm = () => {
    return (
      <Row type="flex">
        <Col span={8}>
          <Form>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <h3>{formatMessage("basic-information")}</h3>
              </div>
            </Col>
            {renderCommonFields()}
            {renderRolesForm()}
            {editing === false ? renderCombinedIngestForm() : null}
            {renderTags()}
            {renderNextStep()}
          </Form>
        </Col>
        <Col style={{ paddingLeft: "10px" }} span={8} offset={1}>
          {renderRetentionPeriodsForm()}
        </Col>
      </Row>
    )
  }

  const getEmailValidateStatus = () => {
    if (!state.email || !state.email.length) {
      return formatMessage("new-ingestion-email-required")
    } else {
      let emailsValid = true
      let i = 0
      while (emailsValid && i < state.email.length) {
        emailsValid = emailUtils.isValidEmail(state.email[i])
        i++
      }
      if (!emailsValid) {
        return formatMessage("new-ingestion-email-not-valid-advise")
      }
    }
    return ""
  }

  const renderBatchForm = () => {
    const emailValidateStatus = getEmailValidateStatus()
    return (
      <Row type="flex">
        <Col span={8}>
          <Form>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <h3>{formatMessage("basic-information")}</h3>
              </div>
            </Col>
            {renderCommonFields()}
            <Form.Item
              label={formatMessage("new-ingestion-email-label")}
              layout="horizontal"
              validateStatus={emailValidateStatus ? "error" : "success"}
              help={emailValidateStatus}
            >
              <EditableTagGroup
                initialValues={state.email}
                updateParent={_onEmailChange}
                addLabel={formatMessage("add-email")}
                isValid={emailUtils.isValidEmail}
                widthInput={200}
              />
            </Form.Item>
            {renderRolesForm()}
            {renderCombinedIngestForm()}
            {renderTags()}
            {renderNextStep()}
          </Form>
        </Col>
        <Col style={{ paddingLeft: "10px" }} span={8} offset={1}>
          {renderRetentionPeriodsForm()}
        </Col>
      </Row>
    )
  }

  if ([INGEST_TYPES["rt"], INGEST_TYPES["nrt"]].includes(type)) {
    return renderRtNrtForm()
  } else if (type === INGEST_TYPES["batch"]) {
    return renderBatchForm()
  } else {
    return null
  }
}

export default Form.create({ name: "new_ingest_data_form" })(IngestDataForm)
