import React from "react"
import ReactJson from "react-json-view"
import { Button, Col, Form, Icon, InputNumber, Row, Slider } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import LabelWithTooltip from "../labelWithTooltip"
import JsonExampleUpload from "../jsonExampleUpload"

const LAYOUT = "horizontal"
const PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT = 100

class IngestRtNrtEventForm extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      modalJSONVisible: false,
      publicationsPerSecond: 0,
    }

    this._getSliderMarks = this._getSliderMarks.bind(this)
    this._renderEventForm = this._renderEventForm.bind(this)
    this._renderNoPubSubAvailableWarning = this._renderNoPubSubAvailableWarning.bind(
      this
    )
    this._onEventChange = this._onEventChange.bind(this)
    this._renderAccumulatorWarning = this._renderAccumulatorWarning.bind(this)
    this._deleteExampleEvent = this._deleteExampleEvent.bind(this)
    this._onPublicationsPerSecondChange = this._onPublicationsPerSecondChange.bind(
      this
    )
    this._nextStep = this._nextStep.bind(this)
    this._computeNextStepDisabled = this._computeNextStepDisabled.bind(this)
  }

  componentDidMount() {
    this.setState({ publicationsPerSecond: this.props.publicationsPerSecond })
  }

  _onPublicationsPerSecondChange(publicationsPerSecond) {
    const accumulatorRequired =
      publicationsPerSecond >=
      PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT

    this.setState(
      { publicationsPerSecond },
      () =>
        typeof this.props.onPublicationsPerSecondChange === "function" &&
        this.props.onPublicationsPerSecondChange({
          publicationsPerSecond,
          accumulatorRequired,
        })
    )
  }

  _deleteExampleEvent() {
    typeof this.props.onEventChange === "function" &&
      this.props.onEventChange(null)
  }

  _onEventChange(event) {
    typeof this.props.onEventChange === "function" &&
      this.props.onEventChange(event)
  }

  _getSliderMarks(limit) {
    const sliderMarks = {
      [limit]: {
        label: <strong>{limit}</strong>,
      },
    }

    if (limit > 1) {
      sliderMarks[1] = {
        label: <strong>1</strong>,
      }
    }

    if (limit > PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT) {
      sliderMarks[PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT] = {
        style: {
          color: "#f62a33",
        },
      }
    }

    return sliderMarks
  }

  _nextStep() {
    typeof this.props.onNextStep === "function" && this.props.onNextStep()
  }

  _computeNextStepDisabled() {
    return (
      !this.props.event ||
      !this.state.publicationsPerSecond ||
      this.state.publicationsPerSecond > this.props.publicationsPerSecondLimit
    )
  }

  _renderNoPubSubAvailableWarning() {
    return (
      <p>
        <Icon type="info-circle" size="small" style={{ color: "#f62a33" }} />
        <span
          style={{
            marginLeft: "5px",
            color: "#f62a33",
            fontSize: "12px",
            fontStyle: "italic",
          }}
        >
          {this._formatMessage("new-ingestion-ingest-no-pubs-per-sec-left")}
        </span>
      </p>
    )
  }

  _renderAccumulatorWarning() {
    return (
      <p>
        <Icon type="info-circle" size="small" style={{ color: "#305ff9" }} />
        <span
          style={{
            marginLeft: "5px",
            color: "#305ff9",
            fontSize: "12px",
            fontStyle: "italic",
          }}
        >
          {this._formatMessage("new-ingestion-ingest-accumulator-warning", {
            limit: PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT,
          })}
        </span>
      </p>
    )
  }

  _renderEventForm() {
    return (
      <Row style={{ marginTop: 30 }}>
        {this.props.event === null || this.props.event === undefined ? (
          <Col span={12}>
            <JsonExampleUpload onUpload={this._onEventChange} />
          </Col>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>{this._formatMessage("example-event-label")}:</h5>
              <button
                title={this._formatMessage("delete-example-event-title")}
                className="button-no-styles"
                onClick={this._deleteExampleEvent}
              >
                <Icon
                  type="delete"
                  size="large"
                  style={{
                    marginRight: "5px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
            <ReactJson
              src={this.props.event}
              sortKeys={true}
              name={false}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
              style={{
                marginBottom: "1rem",
                maxHeight: "200px",
                overflow: "auto",
              }}
            />
          </>
        )}
      </Row>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Form>
        <p style={{ marginBottom: "2rem" }}>
          <h4
            style={{
              textTransform: "uppercase",
              fontSize: "1rem",
              color: "grey",
            }}
          >
            <LabelWithTooltip
              label={this._formatMessage("new-ingestion-ingest-volume-title")}
              tooltip={this._formatMessage(
                "new-ingestion-ingest-volume-tooltip"
              )}
            />
          </h4>
          <Form.Item
            label={
              <span>
                {this._formatMessage("new-ingestion-ingest-pubs-per-sec-title")}{" "}
                (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/help/limits"
                  style={{ fontSize: ".75rem" }}
                >
                  {this._formatMessage("new-ingestion-more-info")}
                </a>
                )
              </span>
            }
            layout={LAYOUT}
          >
            {getFieldDecorator("publicationsPerSecond", {
              initialValue: this.props.publicationsPerSecond || 0,
              rules: [
                {
                  required: true,
                  message: this._formatMessage(
                    "new-ingestion-ingest-value-required"
                  ),
                },
              ],
            })(
              <>
                <Row>
                  <Col span={8}>
                    <Slider
                      name="publicationsPerSecond"
                      min={0}
                      max={this.props.publicationsPerSecondLimit}
                      defaultValue={this.props.publicationsPerSecond || 1}
                      value={this.state.publicationsPerSecond}
                      disabled={
                        this.props.publicationsPerSecond >
                          this.props.publicationsPerSecondLimit ||
                        this.props.publicationsPerSecondLimit === 0
                      }
                      marks={this._getSliderMarks(
                        this.props.publicationsPerSecondLimit
                      )}
                      onChange={this._onPublicationsPerSecondChange}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      style={{ marginLeft: "16px" }}
                      min={0}
                      max={this.props.publicationsPerSecondLimit}
                      defaultValue={this.props.publicationsPerSecond || 1}
                      value={this.state.publicationsPerSecond}
                      disabled={
                        this.props.publicationsPerSecond >
                          this.props.publicationsPerSecondLimit ||
                        this.props.publicationsPerSecondLimit === 0
                      }
                      onChange={this._onPublicationsPerSecondChange}
                    />
                  </Col>
                </Row>
                <Row style={{ lineHeight: "10px" }}>
                  {this.props.publicationsPerSecondLimit === 0
                    ? this._renderNoPubSubAvailableWarning()
                    : this.state.publicationsPerSecond >
                      PUBLICATIONS_PER_SECOND_WITHOUTH_ACCUMULATOR_LIMIT
                    ? this._renderAccumulatorWarning()
                    : null}
                </Row>
              </>
            )}
          </Form.Item>
          {this._renderEventForm()}
        </p>
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <Button
              type="primary"
              onClick={this._nextStep}
              disabled={this._computeNextStepDisabled()}
            >
              {this._formatMessage("next-step")}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create({ name: "new_ingest_event_form" })(
  injectIntl(IngestRtNrtEventForm)
)
