import React from "react"
import {
  Button,
  Form,
  Icon,
  message,
  Modal,
  Tooltip,
  Switch,
  Upload,
} from "antd"
import Papa from "papaparse"
import { injectIntl } from "gatsby-plugin-intl"
import ReactJson from "react-json-view"
import IngestTag from "../ingestTag"
import LabelWithTooltip from "../labelWithTooltip"

class CsvExampleUpload extends React.Component {
  constructor(props) {
    super(props)

    this.fileReader = {}

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.___dummyRequest = this.___dummyRequest.bind(this)
    this._handleUpload = this._handleUpload.bind(this)
    this._onHeadersProvidedSeparatelyChange = this._onHeadersProvidedSeparatelyChange.bind(
      this
    )
    this._renderEventForm = this._renderEventForm.bind(this)
    this._renderEventDisplay = this._renderEventDisplay.bind(this)
    this._deleteExampleEvent = this._deleteExampleEvent.bind(this)
    this._openModalCSVWarning = this._openModalCSVWarning.bind(this)
  }

  componentDidMount() {
    this._initFileReaders()
  }

  _csvToJson(csv) {
    return csv
      .map(c => {
        const newContent = {}

        Object.entries(c).forEach(([k, v]) => {
          newContent[k] = v instanceof Date ? v.toISOString() : v
        })

        return newContent
      })
      .pop()
  }

  _initFileReaders() {
    this.fileReader = new FileReader()
    this.fileReader.onload = csv => {
      try {
        let content = Papa.parse(csv.target.result, {
          header: true,
          preview: 1,
          skipEmptyLines: true,
        })
        const headers = (content.meta && content.meta.fields) || []
        const headersThatDoNotMatchRequirements = (headers || []).filter(
          header =>
            !/^[a-z|A-Z|-|_]+(a-z|A-Z|0-9|-|_)*/.test(header) ||
            / /g.test(header)
        )
        const headersMeetRestrictions =
          headersThatDoNotMatchRequirements.length <= 0

        if (headersMeetRestrictions) {
          content = !content.data ? content : content.data

          const event = this._csvToJson(content)

          typeof this.props.onEventChange === "function" &&
            this.props.onEventChange(event)
        } else {
          message.error(
            this._formatMessage("new-ingestion-csv-headers-error", {
              headers:
                headersThatDoNotMatchRequirements.length > 1
                  ? headersThatDoNotMatchRequirements.join('", "')
                  : `"${headersThatDoNotMatchRequirements[0]}"`,
            })
          )
        }
      } catch (ex) {
        console.error(`[ERROR]: ${ex}`)
      }
    }
  }

  _openModalCSVWarning() {
    const __formatMessage = this._formatMessage

    return new Promise((ok, ko) => {
      Modal.confirm({
        title: __formatMessage(
          "page-new-ingestion-csv-before-upload-modal-title"
        ),
        content: (
          <p
            dangerouslySetInnerHTML={{
              __html: __formatMessage(
                "page-new-ingestion-csv-before-upload-modal-content"
              ),
            }}
          ></p>
        ),
        okText: __formatMessage("continue"),
        cancelText: __formatMessage("cancel"),
        onOk: () => ok(),
        onCancel: () => ko(),
      })
    })
  }

  _onHeadersProvidedSeparatelyChange(headersProvidedSeparately) {
    if (
      headersProvidedSeparately &&
      this.props.event &&
      this.props.headersOrder === null
    ) {
      const that = this

      return new Promise((ok, ko) => {
        Modal.confirm({
          title: that._formatMessage(
            "page-new-ingestion-csv-before-upload-modal-title"
          ),
          content: (
            <p
              dangerouslySetInnerHTML={{
                __html: that._formatMessage(
                  "page-new-ingestion-csv-headers-legacy-modal-content"
                ),
              }}
            ></p>
          ),
          okText: that._formatMessage("continue"),
          cancelText: that._formatMessage("cancel"),
          onOk: () => {
            that._deleteExampleEvent()
            that._propagateHeadersProvidedSeparately(headersProvidedSeparately)
            ok()
          },
          onCancel: () => {
            that._propagateHeadersProvidedSeparately(false)
            ko()
          },
        })
      })
    } else {
      this._propagateHeadersProvidedSeparately(headersProvidedSeparately)
    }
  }

  _propagateHeadersProvidedSeparately(headersProvidedSeparately) {
    typeof this.props.onHeadersProvidedSeparatelyChange === "function" &&
      this.props.onHeadersProvidedSeparatelyChange(headersProvidedSeparately)
  }

  // https://medium.com/@mattcroak718/managing-file-uploads-with-ant-design-6d78e592f2c4
  ___dummyRequest({ onSuccess }) {
    setTimeout(() => onSuccess("ok"), 0)
  }

  _handleUpload(data) {
    if (data && data.file && data.file.status === "done") {
      this.fileReader.readAsText(data.file.originFileObj)
    }
  }

  _deleteExampleEvent() {
    typeof this.props.onEventChange === "function" &&
      this.props.onEventChange(null)
  }

  _renderEventForm() {
    return (
      <>
        <Form.Item label={this._formatMessage("upload-csv-label")}>
          <div style={{ marginBottom: "1rem" }}>
            <Upload
              id="file"
              type="file"
              name="eventFile"
              accept=".csv"
              listType="text"
              multiple={false}
              showUploadList={false}
              customRequest={this.___dummyRequest}
              onChange={this._handleUpload}
              beforeUpload={this._openModalCSVWarning}
            >
              <Button>
                <Icon type="upload" />{" "}
                {this._formatMessage("click-to-upload-csv")}
              </Button>
            </Upload>
          </div>
          <div
            style={{
              display: "block",
              flexDirection: "column",
              border: "1px solid #e8e8e8",
              borderRadius: "10px",
              padding: "1rem",
              width: "fit-content",
            }}
          >
            <b
              style={{
                fontSize: "0.75rem",
                textTransform: "uppercase",
              }}
            >
              {this._formatMessage("csv-file-restrictions-header")}
            </b>
            <ul
              className="metadata-index-indications"
              style={{ paddingTop: 0 }}
              dangerouslySetInnerHTML={{
                __html: this._formatMessage("csv-file-restrictions"),
              }}
            ></ul>
          </div>
        </Form.Item>
      </>
    )
  }

  _renderEventDisplay() {
    const { headersOrder, event } = this.props
    const headers = Array.isArray(headersOrder)
      ? headersOrder.slice()
      : Array.isArray(event) && Object.keys(event[0])

    return (
      <>
        {this.props.headersProvidedSeparately ? (
          <>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "10px",
                textTransform: "uppercase",
              }}
            >
              <span style={{ marginRight: "4px" }}>
                {this._formatMessage("headers-csv-order")}
              </span>
              <Tooltip
                title={this._formatMessage("headers-csv-order-tooltip-title")}
              >
                <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "1rem",
                padding: "1rem",
                border: "1px solid #e8e8e8",
                borderRadius: "10px",
                overflowY: "auto",
                maxHeight: "250px",
              }}
            >
              {headers.map(h => (
                <IngestTag>{h}</IngestTag>
              ))}
            </div>
          </>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "10px",
              textTransform: "uppercase",
            }}
          >
            {this._formatMessage("example-event-label")}
          </span>
          <button
            title={this._formatMessage("delete-example-event-title")}
            class="button-no-styles"
            onClick={this._deleteExampleEvent}
          >
            <Icon
              type="delete"
              size="large"
              style={{
                marginRight: "5px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          </button>
        </div>
        <ReactJson
          src={this.props.event}
          sortKeys={true}
          name={false}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={false}
          style={{
            marginBottom: "1rem",
            maxHeight: "200px",
            overflow: "auto",
            padding: "1rem",
            border: "1px solid #e8e8e8",
            borderRadius: "10px",
          }}
        />
      </>
    )
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "1rem 0",
            fontWeight: "bold",
          }}
        ></div>
        <h4
          style={{
            textTransform: "uppercase",
            fontSize: "1rem",
            color: "grey",
          }}
        >
          {this._formatMessage("example-event-label")}
        </h4>
        <div style={{ display: "flex", margin: "1rem 0" }}>
          <Switch
            defaultChecked={this.props.headersProvidedSeparately}
            checked={this.props.headersProvidedSeparately}
            onChange={this._onHeadersProvidedSeparatelyChange}
            style={{ marginRight: "0.5rem" }}
            checkedChildren="ON "
            unCheckedChildren="OFF"
          />
          <LabelWithTooltip
            label={`${this._formatMessage("upload-csv-headers-check")}.`}
            tooltip={this._formatMessage("upload-csv-headers-check-tooltip")}
          />
        </div>
        {this.props.event === null || this.props.event === undefined
          ? this._renderEventForm()
          : this._renderEventDisplay()}
      </>
    )
  }
}

export default injectIntl(CsvExampleUpload)
