import React from "react"
import { Icon, Tooltip } from "antd"

const LabelWithTooltip = ({ label, tooltip, required, tooltipWidth }) => {
  const overlayStyle = {}
  if (tooltipWidth) {
    overlayStyle.maxWidth = `${tooltipWidth}px`
  }
  return (
    <span>
      {required && (
        <span
          style={{ color: "#F5222D", fontSize: "14px", marginRight: "5px" }}
        >
          *
        </span>
      )}
      <span style={{ marginRight: "5px" }}>{label}</span>
      <Tooltip title={tooltip} overlayStyle={overlayStyle}>
        <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
      </Tooltip>
    </span>
  )
}

export default LabelWithTooltip
