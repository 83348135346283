import React from "react"
import { Button, Form, Checkbox, message } from "antd"
import * as IngestService from "../../services/ingest"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import LocalizedAniLink from "../localizedAniLink"
import { INGEST_TYPES } from "../../services/constants"

class IngestActivate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkboxChecked: false,
      iconLoading: false,
    }

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this._handleSubmit = this._handleSubmit.bind(this)
    this._ingestValidate = this._ingestValidate.bind(this)
    this._onCheckboxChange = this._onCheckboxChange.bind(this)
  }

  _ingestValidate(ingest) {
    if (!ingest.alias) {
      message.error(this._getValidationMessage("alias"))
      this._goToStepWithError("data")
      return false
    }

    if (!ingest.name) {
      message.error(this._getValidationMessage("name"))
      this._goToStepWithError("data")
      return false
    }

    if (!ingest.version) {
      message.error(this._getValidationMessage("version"))
      this._goToStepWithError("data")
      return false
    }

    if (!ingest.description) {
      message.error(this._getValidationMessage("description"))
      this._goToStepWithError("data")
      return false
    }

    if (
      [INGEST_TYPES["batch"], INGEST_TYPES["metadata"]].includes(ingest.type) &&
      (!ingest.email || ingest.email === "")
    ) {
      message.error(this._getValidationMessage("email"))
      this._goToStepWithError("data")
      return false
    }

    if (!ingest.dictionaryId || ingest.dictionaryId === "") {
      message.error(this._getValidationMessage("dictionaryId"))
      this._goToStepWithError("dictionaryId")
      return false
    }

    if (
      !ingest.context ||
      !ingest.context.option ||
      ingest.context.option === ""
    ) {
      message.error(this._getValidationMessage("context"))
      this._goToStepWithError("context")
      return false
    }
    if (
      ingest.context &&
      ingest.context.option === IngestService.CONTEXT_FORM_FIXED_OPTION
    ) {
      if (!this._validateContextStep(ingest, "site")) {
        message.error(this._getValidationMessage("context-site"))
        this._goToStepWithError("context")
        return false
      }
      if (!this._validateContextStep(ingest, "source")) {
        message.error(this._getValidationMessage("context-source"))
        this._goToStepWithError("context")
        return false
      }
    }
    if (
      ingest.context &&
      ingest.context.option === IngestService.CONTEXT_FORM_FILTERED_OPTION
    ) {
      if (!this._validateContextStep(ingest, "fieldName")) {
        message.error(this._getValidationMessage("context-fieldName"))
        this._goToStepWithError("context")
        return false
      }

      if (!this._validateContextStep(ingest, "delimiter")) {
        message.error(this._getValidationMessage("context-delimiter"))
        this._goToStepWithError("context")
        return false
      }
    }

    if (
      ingest.context &&
      ingest.context.option === IngestService.CONTEXT_FORM_INFORMED_OPTION
    ) {
      if (!this._validateContextStep(ingest, "confirmInformedContext")) {
        message.error(
          this._getValidationMessage("context-confirmInformedContext")
        )
        this._goToStepWithError("context")
        return false
      }
    }

    if (
      [INGEST_TYPES["rt"], INGEST_TYPES["nrt"]].includes(ingest.type) &&
      (!ingest.publicationsPerSecond || ingest.publicationsPerSecond <= 0)
    ) {
      message.error(this._getValidationMessage("publicationsPerSecond"))
      this._goToStepWithError("event")
      return false
    }

    if (
      [INGEST_TYPES["batch"], INGEST_TYPES["metadata"]].includes(ingest.type) &&
      !ingest.separator
    ) {
      message.error(this._getValidationMessage("separator"))
      this._goToStepWithError("event")
      return false
    }

    if (!ingest.event || Object.keys(event).length <= 0) {
      message.error(this._getValidationMessage("event"))
      this._goToStepWithError("event")
      return false
    }

    if (!ingest.mappings || ingest.mappings.length < 2) {
      message.error(this._getValidationMessage("mappings"))
      this._goToStepWithError("mapping")
      return false
    }

    return true
  }

  _getValidationMessage(id) {
    return this._formatMessage(`ingestion-service-${id}-validation`)
  }

  _validateContextStep(ingest, field) {
    if (!ingest.context[field] || ingest.context[field] === "") {
      this._goToStepWithError("context")
      return false
    }
    return true
  }

  _goToStepWithError(field) {
    let step = null

    switch (field) {
      case "data":
        step = 0
        break
      case "dictionaryId":
        step = 1
        break
      case "context":
        step = 2
        break
      case "event":
        step = 3
        break
      case "mapping":
        step = 4
        break
      case "others":
        step = 5
        break
      default:
        break
    }

    const { goToStep } = this.props

    if (typeof goToStep === "function" && step >= 0) {
      goToStep(step)
    }
  }

  _handleSubmit() {
    if (this._ingestValidate(this.props.ingest)) {
      this._changeIconLoading(true)
      IngestService.createIngest(this.props.ingest)
        .then(data => {
          message.info(this._formatMessage("ingestion-activate-ok"))
          setTimeout(() => {
            let url = `/ingest/creating?id=${data.id}&t=${this.props.ingest.type}`

            url = this.props.ingest.accumulatorRequired
              ? `${url}&accumulator=true`
              : url
            navigate(url)
          }, 1500)
        })
        .catch(reason => {
          this._changeIconLoading(false)
          console.error(`[ERROR]: Creating new ingest: ${reason}`)
          message.error(this._formatMessage("ingestion-activate-ko"))
        })
    }
  }

  _changeIconLoading(iconLoading) {
    this.setState({
      iconLoading,
    })
  }

  _onCheckboxChange() {
    this.setState({ checkboxChecked: !this.state.checkboxChecked })
  }

  render() {
    return (
      <div style={{ margin: "16 0", minHeight: 120 }} className="content">
        <h1>{this._formatMessage("ingestion-activate-component-header")}</h1>
        <Form>
          <Form.Item>
            <Checkbox
              onChange={this._onCheckboxChange}
              value={this.state.checkboxChecked}
            >
              {this._formatMessage("ingestion-activate-component-text")}
              <a href="/help/pricing" target="_blank">
                {this._formatMessage("new-ingestion-more-info")}
              </a>
              .
            </Checkbox>
          </Form.Item>
          <Button
            type="primary"
            disabled={!this.state.checkboxChecked}
            loading={this.state.iconLoading}
            onClick={this._handleSubmit}
          >
            {this._formatMessage("new-ingestion-create")}
          </Button>
        </Form>
      </div>
    )
  }
}
export default injectIntl(
  Form.create({ name: "ingest_create" })(IngestActivate)
)
