import React from "react"
import { Button, Col, Form, Icon, Input, Row, Select } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import { BiBracket } from "react-icons/bi"
import { BsBraces } from "react-icons/bs"
import _ from "lodash"

import JsonExampleUpload from "../jsonExampleUpload"
import CsvExampleUpload from "./csvExampleUpload"

const CSV_FILE = "CSV"
const JSON_FILE = "JSON"
const JSONLINES_FILE = "JSONL"

const FILE_TYPES = [CSV_FILE, JSON_FILE, JSONLINES_FILE]

const DEFAULT_FILE_TYPE = CSV_FILE

const { Option } = Select

class IngestBatchEventForm extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      separator: "",
      currentFileType:
        (this.props.event && this.props.event.fileType) || DEFAULT_FILE_TYPE,
    }

    this._onEventChange = this._onEventChange.bind(this)
    this._onHeadersProvidedSeparatelyChange = this._onHeadersProvidedSeparatelyChange.bind(
      this
    )
    this._onDeleteEventClick = this._onDeleteEventClick.bind(this)
    this._onSeparatorChange = this._onSeparatorChange.bind(this)
    this._onFileTypeSelect = this._onFileTypeSelect.bind(this)
    this._nextStep = this._nextStep.bind(this)
    this._computeNextStepDisabled = this._computeNextStepDisabled.bind(this)
    this._renderOption = this._renderOption.bind(this)
    this._renderUploader = this._renderUploader.bind(this)
  }

  componentDidMount() {
    this.setState({ separator: this.props.separator })
  }

  static _isObject(obj) {
    return !!(obj && typeof obj === "object" && obj.constructor === Object)
  }

  _populateEvent(event) {
    typeof this.props.onEventChange === "function" &&
      typeof this.props.onEventChange(event)
  }

  _populateSeparator(separator) {
    typeof this.props.onSeparatorChange === "function" &&
      this.props.onSeparatorChange(separator)
  }

  _onEventChange(newEvent) {
    const event = {
      event: newEvent,
      fileType: this.state.currentFileType,
    }

    this._populateEvent(event)
  }

  _onHeadersProvidedSeparatelyChange(headersProvidedSeparately) {
    typeof this.props.onHeadersProvidedSeparatelyChange === "function" &&
      this.props.onHeadersProvidedSeparatelyChange(headersProvidedSeparately)
  }

  _onDeleteEventClick() {
    this._onEventChange(null)
  }

  _onSeparatorChange(ev) {
    const separator = ev && ev.currentTarget && ev.currentTarget.value
    this.setState({ separator }, () => this._populateSeparator(separator))
  }

  _onFileTypeSelect(currentFileType) {
    this.setState({ currentFileType }, () => {
      const event = {
        event: null,
        fileType: currentFileType,
      }

      if (CSV_FILE !== currentFileType) {
        this._onHeadersProvidedSeparatelyChange(false)
        if (!this.state.separator) {
          this._onSeparatorChange(this._getDefaultSeparator())
        }
      }

      this._populateEvent(event)
    })
  }

  _getDefaultSeparator() {
    return {
      currentTarget: {
        value: ",",
      },
    }
  }

  _nextStep() {
    typeof this.props.onNextStep === "function" && this.props.onNextStep()
  }

  _computeNextStepDisabled() {
    const eventPresent =
      this.props.event && IngestBatchEventForm._isObject(this.props.event.event)

    if (this.state.currentFileType === CSV_FILE) {
      return !this.state.separator || !eventPresent
    }
    return !eventPresent
  }

  _getIconForOption(option) {
    switch (option) {
      case CSV_FILE:
        return <Icon type="file-excel" />
      case JSON_FILE:
        return <BsBraces />
      case JSONLINES_FILE:
        return <BiBracket />
      default:
        return null
    }
  }

  _renderOption(option) {
    const icon = this._getIconForOption(option)

    return (
      <Option key={option} value={option}>
        <span style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <span style={{ marginLeft: 5 }}>{option}</span>
        </span>
      </Option>
    )
  }

  _renderUploader(fileType, event) {
    switch (fileType) {
      case CSV_FILE:
        return (
          <>
            <Row>
              <CsvExampleUpload
                event={event}
                headersProvidedSeparately={this.props.headersProvidedSeparately}
                headersOrder={this.props.headersOrder}
                onEventChange={this._onEventChange}
                onHeadersProvidedSeparatelyChange={
                  this._onHeadersProvidedSeparatelyChange
                }
              />
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: "10px" }}>
                <Form.Item
                  label={this._formatMessage("new-ingestion-separator-label")}
                  layout="horizontal"
                >
                  <Input
                    name="separator"
                    defaultValue={this.props.separator}
                    onChange={this._onSeparatorChange}
                    placeholder={this._formatMessage(
                      "new-ingestion-separator-placeholder"
                    )}
                    style={{ maxWidth: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )
      case JSON_FILE:
      case JSONLINES_FILE:
        return (
          <JsonExampleUpload event={event} onUpload={this._onEventChange} />
        )
    }
  }

  render() {
    let renderEvent
    let renderFileType

    if (!this.props.event) {
      renderFileType = this.state.currentFileType || DEFAULT_FILE_TYPE
      renderEvent = null
    } else {
      const { fileType, event } = this.props.event

      renderFileType = fileType || this.state.currentFileType
      renderEvent = event ? _.cloneDeep(event) : null

      if (
        (renderFileType === CSV_FILE || renderFileType === JSONLINES_FILE) &&
        renderEvent
      ) {
        renderEvent = Array.isArray(renderEvent) ? renderEvent : [renderEvent]
      }
    }

    return (
      <>
        <Row>
          <Col span={24}>
            <Form.Item
              label={this._formatMessage(
                "new-ingestion-batch-event-selector-label"
              )}
              layout="horizontal"
            >
              <Select
                defaultValue={renderFileType}
                style={{ maxWidth: 150 }}
                onSelect={this._onFileTypeSelect}
              >
                {FILE_TYPES.map(o => this._renderOption(o))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {this._renderUploader(renderFileType, renderEvent)}
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <Button
              type="primary"
              onClick={this._nextStep}
              disabled={this._computeNextStepDisabled()}
            >
              {this._formatMessage("next-step")}
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default injectIntl(IngestBatchEventForm)
