import React from "react"
import { Button, Icon, message, Tooltip, Upload } from "antd"
import { injectIntl } from "gatsby-plugin-intl"

import ModalJSONCreateForm from "./modalToLoadJson"
import JsonExampleDisplay from "./jsonExampleDisplay"

class JsonExampleUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalJSONVisible: false,
    }

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.fileReader = null
    this.formRef = null
    this.jsonLinesParser = null

    this._handleUpload = this._handleUpload.bind(this)
    this._onDeleteClick = this._onDeleteClick.bind(this)
    this._onJsonCreateOk = this._onJsonCreateOk.bind(this)
    this._showModalJSON = this._showModalJSON.bind(this)
    this._hideModalJSON = this._hideModalJSON.bind(this)
    this.___dummyRequest = this.___dummyRequest.bind(this)
    this.___saveFormRef = this.___saveFormRef.bind(this)
  }

  componentDidMount() {
    this.fileReader = new FileReader()
    this.fileReader.onload = json => {
      try {
        const event = this._readJSON(json.target.result)

        typeof this.props.onUpload === "function" && this.props.onUpload(event)
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  _readJSON(file) {
    let event = null

    if (!file) {
      event = null
    } else {
      let content

      try {
        event = JSON.parse(file)
      } catch (ex) {
        try {
          content = file.split(/\r*\n/g)

          event = !content.data ? content : content.data

          if (Array.isArray(event) && event.length) {
            event = event[0]
          }

          event = JSON.parse(event)
        } catch (ex) {
          event = null
          console.error(`[ERROR]: Processing JSON/JSONL ${ex}`)
          message.error(
            this._formatMessage(
              "new-ingestion-event-event-json-not-proccesable"
            )
          )
        }
      }
    }

    return event
  }

  _handleUpload(data) {
    if (data && data.file && data.file.status === "done") {
      try {
        this.fileReader.readAsText(data.file.originFileObj)
      } catch (ex) {
        message.error(ex)
      }
    }
  }

  _onJsonCreateOk() {
    const { form } = this.formRef.props

    form.validateFields((err, values) => {
      if (err) {
        return
      } else {
        let json

        try {
          json = this._readJSON(values.payload)
        } catch (err) {
          json = null
        } finally {
          this.setState({ modalJSONVisible: false }, () => {
            typeof this.props.onUpload === "function" &&
              this.props.onUpload(json)

            form.resetFields()
          })
        }
      }
    })
  }

  _onDeleteClick() {
    typeof this.props.onUpload === "function" && this.props.onUpload(null)
  }

  _showModalJSON() {
    this.setState({ modalJSONVisible: true })
  }

  _hideModalJSON() {
    this.setState({ modalJSONVisible: false })
  }

  // https://medium.com/@mattcroak718/managing-file-uploads-with-ant-design-6d78e592f2c4
  ___dummyRequest({ onSuccess }) {
    setTimeout(() => onSuccess("ok"), 0)
  }

  ___saveFormRef(formRef) {
    this.formRef = formRef
  }

  render() {
    return this.props.event === null || this.props.event === undefined ? (
      <>
        <h4
          style={{
            textTransform: "uppercase",
            fontSize: "1rem",
            color: "grey",
          }}
        >
          {this._formatMessage("example-event-label")}
          <Tooltip
            title={
              <span>{this._formatMessage("example-event-explanation")}</span>
            }
          >
            <Icon
              type="info-circle"
              style={{
                color: "rgba(0,0,0,.45)",
                marginLeft: "5px",
              }}
            />
          </Tooltip>
        </h4>
        <p
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <Upload
            id="file"
            type="file"
            name="jsonFile"
            accept=".json,.jsonl"
            showUploadList={false}
            multiple={false}
            customRequest={this.___dummyRequest}
            onChange={this._handleUpload}
          >
            <Button style={{ marginRight: "10px" }}>
              <Icon type="upload" />
              {this._formatMessage("click-to-upload-json")}
            </Button>
          </Upload>
          <Button onClick={this._showModalJSON}>
            <Icon type="edit" />
            {this._formatMessage("edit-manually")}
          </Button>
        </p>
        <ModalJSONCreateForm
          wrappedComponentRef={this.___saveFormRef}
          visible={this.state.modalJSONVisible}
          onCancel={this._hideModalJSON}
          onCreate={this._onJsonCreateOk}
        />
      </>
    ) : (
      <JsonExampleDisplay
        event={this.props.event}
        onDeleteClick={this._onDeleteClick}
      />
    )
  }
}

export default injectIntl(JsonExampleUpload)
