import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tabs,
  Tooltip,
} from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import * as IngestService from "../../services/ingest"

const { TabPane } = Tabs
const { Option } = Select

class IngestContextSelector extends React.Component {
  static propTypes = {
    sites: PropTypes.arrayOf(PropTypes.object),
    site: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.object),
    source: PropTypes.string,
    prefix: PropTypes.string,
    prefixes: PropTypes.arrayOf(PropTypes.object),
    dictionaryFields: PropTypes.arrayOf(PropTypes.object),
    fieldName: PropTypes.string,
    delimiter: PropTypes.string,
    onSubmit: PropTypes.func,
    onContextChange: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      site: "",
      source: "",
      fieldName: "",
      delimiter: "",
      confirmInformedContext:
        this.props.selectedTab && this.props.selectedTab === 3,
      formCorrectlyFullfilled: false,
      context: null,
      selectedTab: this.props.selectedTab,
    }

    this._bindMethods()
  }

  componentDidMount() {
    this._restoreFormValues()
  }

  _bindMethods() {
    this._onInputDelimiter = this._onInputDelimiter.bind(this)
    this._onCheckboxChange = this._onCheckboxChange.bind(this)
    this._onSelectSite = this._onSelectSite.bind(this)
    this._onSelectSource = this._onSelectSource.bind(this)
    this._onSelectField = this._onSelectField.bind(this)
    this._onTabChange = this._onTabChange.bind(this)
    this._renderSetFixedContext = this._renderSetFixedContext.bind(this)
    this._renderSetFilterContext = this._renderSetFilterContext.bind(this)
    this._renderSetSkipContext = this._renderSetSkipContext.bind(this)
    this._renderContextForm = this._renderContextForm.bind(this)
    this._renderFieldSelectors = this._renderFieldSelectors.bind(this)
    this._nextStep = this._nextStep.bind(this)
  }

  _restoreFormValues() {
    const { site, source, prefix, fieldName, delimiter, option } = this.props
    const { form } = this.props
    let selectedTab = 1

    switch (option) {
      case IngestService.CONTEXT_FORM_FIXED_OPTION:
        selectedTab = 1
        break
      case IngestService.CONTEXT_FORM_FILTERED_OPTION:
        selectedTab = 2
        break
      case IngestService.CONTEXT_FORM_INFORMED_OPTION:
        selectedTab = 3
        break
    }

    this.setState(
      {
        selectedTab,
        site,
        source,
        prefix,
        fieldName,
        delimiter,
      },
      () => {
        form.setFieldsValue({
          site,
          source,
          prefix,
          fieldName,
          delimiter,
        })
        this._setFormCorrectlyFullfilledValue()
      }
    )
  }

  _buildContextObject() {
    let option = ""
    let {
      site,
      source,
      prefix,
      fieldName,
      delimiter,
      selectedTab,
      confirmInformedContext,
    } = this.state

    switch (selectedTab) {
      case 1:
        option = IngestService.CONTEXT_FORM_FIXED_OPTION
        confirmInformedContext = false
        break
      case 2:
        option = IngestService.CONTEXT_FORM_FILTERED_OPTION
        site = null
        source = null
        prefix = null
        confirmInformedContext = false
        break
      case 3:
        option = IngestService.CONTEXT_FORM_INFORMED_OPTION
        site = null
        source = null
        prefix = null
        fieldName = null
        delimiter = null
        break
      default:
        console.error(
          `[ERROR]: "selectedTab" not properly set. selectedTab=${selectedTab}`
        )
    }

    return {
      option,
      site,
      source,
      prefix,
      fieldName,
      delimiter,
      confirmInformedContext,
    }
  }

  _updateContext() {
    const context = this._buildContextObject()

    typeof this.props.onContextChange === "function" &&
      this.props.onContextChange(context)

    this._setFormCorrectlyFullfilledValue()
  }

  _updatePrefix() {
    const { site = "", source = "" } = this.state
    let value = ""
    let prefix

    if (site && source) {
      const { prefixes = [] } = this.props

      prefix = prefixes.find(p => p.value === `${site}${source}`)
      value = (prefix && prefix.value) || ""
    }

    this.props.form.setFieldsValue({
      prefix: value,
    })

    this.setState({ prefix: value }, this._updateContext)
  }

  _setFormCorrectlyFullfilledValue() {
    const {
      site,
      source,
      prefix,
      fieldName,
      delimiter,
      confirmInformedContext,
    } = this.state

    let formCorrectlyFullfilled = false

    switch (this.state.selectedTab) {
      case 1:
        formCorrectlyFullfilled =
          site && source && prefix && fieldName && delimiter
        break
      case 2:
        formCorrectlyFullfilled = fieldName && delimiter
        break
      case 3:
        formCorrectlyFullfilled = confirmInformedContext
        break
    }

    this.setState({ formCorrectlyFullfilled })
  }

  _onSelectSite(site) {
    this.setState({ site }, this._updatePrefix)
  }

  _onSelectSource(source) {
    this.setState({ source }, this._updatePrefix)
  }

  _onSelectField(fieldName) {
    this.setState({ fieldName }, this._updateContext)
  }

  _onTabChange(key) {
    const selectedTab = Number(key)

    this.setState({ selectedTab }, this._updateContext)
  }

  _onCheckboxChange(ev) {
    const { checked } = ev && ev.target

    this.setState({ confirmInformedContext: checked }, this._updateContext)
  }

  _onInputDelimiter(ev) {
    const delimiter = ev && ev.target && ev.target.value

    this.setState({ delimiter }, this._updateContext)
  }

  _nextStep() {
    typeof this.props.onNextStep === "function" && this.props.onNextStep()
  }

  _renderSetFixedContext() {
    return (
      <>
        {this._renderContextForm()}
        {this._renderFieldSelectors()}
      </>
    )
  }

  _renderSetFilterContext() {
    return this._renderFieldSelectors()
  }

  _renderSetSkipContext() {
    return (
      <div style={{ display: "flex" }}>
        <Checkbox
          id="checkbox"
          defaultChecked={this.props.selectedTab === 3}
          onChange={this._onCheckboxChange}
          style={{ marginRight: "10px" }}
        ></Checkbox>
        <label
          for="checkbox"
          dangerouslySetInnerHTML={{
            __html: this._formatMessage("new-ingestion-context-skip-content"),
          }}
        ></label>
      </div>
    )
  }

  _renderContextForm() {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Form.Item label="Site">
          {getFieldDecorator("site", {
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-context-must-choose-site"
                ),
              },
            ],
          })(
            <Select
              showSearch={true}
              defaultValue={this.props.site}
              placeholder={this._formatMessage(
                "new-ingestion-context-choose-site-placeholder"
              )}
              onSelect={this._onSelectSite}
            >
              {(this.props.sites || []).map(({ value }) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Source">
          {getFieldDecorator("source", {
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-context-must-choose-source"
                ),
              },
            ],
          })(
            <Select
              showSearch={true}
              placeholder={this._formatMessage(
                "new-ingestion-context-choose-source-placeholder"
              )}
              defaultValue={this.props.source}
              onSelect={this._onSelectSource}
            >
              {(this.props.sources || []).map(({ value }) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Prefix">
          {getFieldDecorator("prefix", {
            initialValue: this.props.prefix,
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-context-no-prefix-found"
                ),
              },
            ],
          })(
            <Input
              defaultValue={this.props.prefix}
              value={this.state.prefix}
              disabled={true}
            ></Input>
          )}
        </Form.Item>
      </>
    )
  }

  _renderFieldSelectors() {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Form.Item
          label={this._formatMessage(
            "new-ingestion-context-choose-dictionary-field-label"
          )}
        >
          {getFieldDecorator("fieldName", {
            initialValue: this.props.fieldName,
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-context-must-choose-dictionary-field"
                ),
              },
            ],
          })(
            <Select
              showSearch={true}
              placeholder={this._formatMessage(
                "new-ingestion-context-choose-dictionary-field-placeholder"
              )}
              onChange={this._onSelectField}
            >
              {(this.props.dictionaryFields || []).map(({ name, type }) => (
                <Option value={name}>
                  {name} <span style={{ fontSize: "10px" }}>({type})</span>
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={this._formatMessage(
            "new-ingestion-context-choose-dictionary-delimiter-label"
          )}
        >
          {getFieldDecorator("delimiter", {
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-context-must-choose-dictionary-delimiter"
                ),
              },
            ],
          })(
            <Input
              initialValue={this.props.delimiter}
              onInput={this._onInputDelimiter}
              placeholder={this._formatMessage(
                "new-ingestion-context-choose-dictionary-delimiter-placeholder"
              )}
            ></Input>
          )}
        </Form.Item>
      </>
    )
  }

  render() {
    return (
      <Form>
        <Tabs
          activeKey={(this.state.selectedTab || 1).toString()}
          onChange={this._onTabChange}
          style={{ marginBottom: "20px" }}
        >
          <TabPane
            tab={
              <span>
                {this._formatMessage("new-ingestion-context-set-tab")}
                <Tooltip
                  title={this._formatMessage(
                    "new-ingestion-context-set-tab-tooltip"
                  )}
                >
                  <Icon
                    type="info-circle"
                    style={{ marginLeft: "5px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </span>
            }
            key="1"
            forceRender={true}
          >
            {this._renderSetFixedContext()}
          </TabPane>
          <TabPane
            tab={
              <span>
                {this._formatMessage("new-ingestion-context-filter-tab")}
                <Tooltip
                  title={this._formatMessage(
                    "new-ingestion-context-filter-tab-tooltip"
                  )}
                >
                  <Icon
                    type="info-circle"
                    style={{ marginLeft: "5px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </span>
            }
            key="2"
            forceRender={true}
          >
            {this._renderSetFilterContext()}
          </TabPane>
          <TabPane
            tab={
              <span>
                {this._formatMessage("new-ingestion-context-skip-tab")}
                <Tooltip
                  title={this._formatMessage(
                    "new-ingestion-context-skip-tab-tooltip"
                  )}
                >
                  <Icon
                    type="info-circle"
                    style={{ marginLeft: "5px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </span>
            }
            key="3"
            forceRender={true}
          >
            {this._renderSetSkipContext()}
          </TabPane>
        </Tabs>
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <Button
              type="primary"
              onClick={this._nextStep}
              disabled={!this.state.formCorrectlyFullfilled}
            >
              {this._formatMessage("next-step")}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default injectIntl(
  Form.create({ name: "new_ingestion_context_form" })(IngestContextSelector)
)
