import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { message, Row, Spin } from "antd"
import queryString from "query-string"

import { INGEST_TYPES } from "../../services/constants"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import RtNrtIngestForm from "../../components/ingest/rtNrtIngestForm"
import NewMetadataIngest from "../../components/newMetadataIngest"
import BatchIngestForm from "../../components/ingest/batchIngestForm"
import * as IngestService from "../../services/ingest"
import useStateObject from "../../hooks/useStateObject"
import withAuth from "../../components/withAuthHoc"

const NewIngest = ({ location }) => {
  const intl = useIntl()

  let queryParams = queryString.parse(location.search)
  const { id, t, linked_with } = queryParams

  const [state, setState] = useStateObject({
    ingest: {
      type: t,
      ...(linked_with && { linked_with }),
    },
    id,
    loading: true,
  })

  const formatMessage = (id, parametrized) =>
    intl.formatMessage({ id }, { ...parametrized })

  useEffect(() => {
    if (state.id) {
      setState({ loading: true })
      IngestService.getIngest(state.id)
        .then(ingest => {
          ingest = state.ingest.linked_with
            ? Object.assign({}, state.ingest, {
                dictionaryId: ingest.dictionaryId,
              })
            : ingest
          setState({ ingest, loading: false })
        })
        .catch(reason => {
          setState({ loading: false })
          console.error(`[ERROR]: Getting ingest by ${state.id}: ${reason}`)
          message.error(formatMessage("new-ingestion-page-load-error"))
        })
    } else {
      setState({ loading: false })
    }
  }, [])

  const formByIngestType = {
    [INGEST_TYPES["rt"]]: <RtNrtIngestForm ingest={state.ingest} />,
    [INGEST_TYPES["batch"]]: <BatchIngestForm ingest={state.ingest} />,
    [INGEST_TYPES["metadata"]]: (
      <NewMetadataIngest
        ingest={state.ingest}
        type={INGEST_TYPES["metadata"]}
      />
    ),
  }

  const renderForm = () => {
    return formByIngestType[state.ingest.type]
  }

  const crumbs = [
    {
      label: formatMessage("menu-ingestions-management"),
      link: "/ingest",
    },
  ]
  if (state.id) {
    crumbs.push({
      label: `${formatMessage("page-ingestion-breadcrumb-detail-of")} ${state
        .ingest.alias || ""}`,
      link: `/ingest/${state.id}`,
    })
  } else {
    crumbs.push({
      label: formatMessage("new-ingestion-breadcrumb"),
      link: "/ingest/options",
    })
  }
  crumbs.push(formatMessage("new-ingestion-create"))

  return (
    <Template selected={["ingest", "ingest-new"]}>
      <CustomBreadcrumb crumbs={crumbs} />
      {state.loading ? (
        <Row
          type="flex"
          justify="center"
          style={{ marginTop: 40, padding: "20px 30px" }}
        >
          <Spin size="large" />
        </Row>
      ) : (
        renderForm()
      )}
    </Template>
  )
}

NewIngest.propTypes = {
  location: PropTypes.object,
}

export default withAuth(NewIngest)
