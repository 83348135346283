import React from "react"
import moment from "moment-timezone"
import { Select } from "antd"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import useStateObject from "../hooks/useStateObject"

const { Option } = Select

const TimezoneSelector = ({ onChange }) => {
  const intl = useIntl()

  const getTimezones = () => {
    return moment.tz
      .names()
      .reduce((memo, tz) => {
        memo.push({
          name: tz,
          offset: moment.tz(tz).utcOffset(),
        })

        return memo
      }, [])
      .sort((a, b) => {
        return a.offset - b.offset
      })
  }

  const [state, setState] = useStateObject({
    timezones: getTimezones(),
    currentValue: "",
  })

  const handleChange = currentValue => {
    setState({ currentValue })
    const currentTz = state.timezones.find(tz => tz.name === currentValue)
    if (typeof onChange === "function") {
      onChange(currentTz.offset ? moment.tz(currentTz.name).format("Z") : "")
    }
  }

  return (
    <div className="container">
      <Select
        showSearch
        placeholder={`${intl.formatMessage({
          id: "timezone-selector-placeholder-prefix",
        })} GMT${moment.tz("Europe/Madrid").format("Z")} Europe/Madrid`}
        onChange={handleChange}
      >
        {state.timezones.map((tz, index) => {
          const timezone = tz.offset ? moment.tz(tz.name).format("Z") : ""

          return (
            <Option key={index} value={tz.name}>
              GMT{timezone} {tz.name}
            </Option>
          )
        })}
      </Select>
    </div>
  )
}

TimezoneSelector.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default TimezoneSelector
