import jmespath from "jmespath"
import safeEval from "safe-eval"
import moment from "moment-timezone"
import _ from "lodash"

import authFetch from "../../../services/network"

export class UserCodeException extends Error {}

export function getMetadataTablesInUse(
  enrichments = [],
  mappings = [],
  event = {}
) {
  const enrichmentDataRequests = (enrichments || []).map(e => {
    const mapping = (mappings || []).filter(
      m => m.destination === e.referenceDictionary
    )
    const path = mapping[0] !== undefined ? mapping[0].path : ""

    if (path) {
      const value = _searchPathValueInEvent(event, path)

      return authFetch(
        `${process.env.GATSBY_CONF_API_URL}/enrichments/${e.metadataTable}/?q=${value}`
      ).then(response => {
        if (response.status !== 200) {
          console.error(`[ERROR]: Retrieving metadata table: ${response}`)
          return Promise.reject(
            `[ERROR]: Retrieving metadata table: ${response}`
          )
        } else {
          return response.json().then(({ item = {} }) => item)
        }
      })
    }
  })

  if (Array.isArray(enrichmentDataRequests) && enrichmentDataRequests.length) {
    return Promise.all(enrichmentDataRequests)
  } else {
    return Promise.resolve()
  }
}

export function _searchPathValueInEvent(event, path) {
  if (!path) {
    return
  }

  let searchPath = ""

  if (path.startsWith(".")) {
    searchPath = path.slice(1)
  } else if (path.startsWith("'.")) {
    searchPath = path.replace(/^'./, "'")
  } else {
    searchPath = path
  }

  return jmespath.search(event, searchPath)
}

export function _getEnrichmentValue(
  enrichment,
  mappings = [],
  metadataTables = [],
  event = {}
) {
  const mapping = (mappings || []).find(
    m => m.destination === enrichment.referenceDictionary
  )
  const path = mapping !== undefined ? mapping.path : ""
  if (path) {
    const val = _searchPathValueInEvent(event, path)
    const table = (metadataTables || []).find(
      pmt =>
        enrichment.metadataTable === `metadata-${pmt.alias}` &&
        pmt[enrichment.referenceMetadata] === val
    )

    if (table) {
      return table[enrichment.origin]
    }
  }

  return enrichment.value || undefined
}

export function _getTypeOfEnrichmentValue(value) {
  if (value === null) {
    return "NULL"
  } else {
    switch (typeof value) {
      case "number":
        return "DOUBLE"
      case "boolean":
        return "BOOLEAN"
      case "string":
      default:
        return "STRING"
    }
  }
}

export function _executeUserSourceCode(
  code,
  input,
  enrichments,
  mappings,
  metadataTables,
  event
) {
  try {
    return safeEval(code)(
      input,
      _getEventForAdvancedTransformations(
        mappings,
        enrichments,
        metadataTables,
        event
      )
    )
  } catch (ex) {
    console.error(ex)
    throw new UserCodeException(ex)
  }
}

export function _getEventForAdvancedTransformations(
  mappings = [],
  enrichments = [],
  metadataTables = [],
  exampleEvent = {}
) {
  const event = {}

  enrichments.forEach(e => {
    event[e.destination] = _getEnrichmentValue(
      e,
      mappings,
      metadataTables,
      exampleEvent
    )
  })

  mappings.forEach(m => {
    if (!event.hasOwnProperty(m.destination)) {
      event[m.destination] = _searchPathValueInEvent(exampleEvent, m.path)
    }
  })

  return event
}

export function _applyDateCasting(date, dateFormat, offset = 0) {
  try {
    if (dateFormat) {
      return moment
        .parseZone(date, dateFormat, true)
        .utcOffset(offset, true)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    } else if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment
        .parseZone(date, null)
        .utcOffset(offset, true)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        .toString()
    } else {
      return null
    }
  } catch (ex) {
    return null
  }
}

export function _applyGenericCasting(initialValue, outputType) {
  let value

  switch (typeof initialValue) {
    case "string":
      switch (outputType) {
        case "LONG":
          value = Math.trunc(_stringToFloat(initialValue))
          break
        case "FLOAT":
        case "DOUBLE":
          value = _stringToFloat(initialValue)
          break
        default:
          value = initialValue
          break
      }
      break
    case "number":
      switch (outputType) {
        case "STRING":
          value = initialValue.toString()
          break
        case "LONG":
          value = Math.trunc(initialValue)
          break
        default:
          value = initialValue
      }
      break
  }

  return value
}

export function _stringToFloat(string) {
  return string === null || string === "" ? NaN : Number(string)
}

export function _checkDestTypeIsCompatible(originalValue, destType) {
  switch (typeof originalValue) {
    case "number":
      return ["FLOAT", "LONG", "DOUBLE"].includes(destType)
        ? originalValue
        : null
    case "string":
      return ["TIMESTAMP", "STRING"].includes(destType) ? originalValue : null
    default:
      return originalValue
  }
}

export function _generatePreview(
  transformationsParam = [],
  enrichmentsParam = [],
  mappingsParam = [],
  dictionaryFields = [],
  event = {},
  metadataTables = []
) {
  const transformations = _.cloneDeep(transformationsParam || [])
  const enrichments = _.cloneDeep(enrichmentsParam || [])
  const mappings = _.cloneDeep(mappingsParam || [])
  const transformationsPreview = (transformations || []).map(t => {
    if (typeof t.value === "undefined") {
      const mapping = (mappings || []).find(m => m.destination == t.destination)
      const enrichment = (enrichments || []).find(
        e => e.destination === t.destination
      )
      let type
      let initialValue
      let value

      if (enrichment) {
        initialValue =
          enrichment.value ||
          _getEnrichmentValue(enrichment, mappings, metadataTables, event)
        type = _getTypeOfEnrichmentValue(initialValue)
      } else if (mapping && mapping.path) {
        const field = (dictionaryFields || []).find(
          d => d.name === mapping.destination
        )

        type = field.type
        initialValue = _searchPathValueInEvent(event, mapping.path)
      } else {
        const field = (dictionaryFields || []).find(
          d => d.name === t.destination
        )
        type = field.type
      }

      if (typeof initialValue !== "undefined" && t.operator === "parse") {
        if (t.outputType === "TIMESTAMP") {
          value = _applyDateCasting(initialValue, t.dateFormat, t.dateOffset)

          if (
            value === "Invalid date" ||
            value === null ||
            !moment(value, moment.ISO_8601, true).isValid()
          ) {
            value = initialValue
            if (type === "TIMESTAMP") {
              t.tsIndexDateError = true
            }
          }

          t.value = displayValue(value)
        } else {
          value = _applyGenericCasting(initialValue, t.outputType)

          if (_checkDestTypeIsCompatible(value, type) === null) {
            t.value = displayValue(initialValue)
            t.typeError = true
          } else {
            delete t.typeError
            t.value = displayValue(value)
          }
        }
      } else if (t.operator === "eval") {
        value = _executeUserSourceCode(
          t.sourceCode,
          initialValue,
          enrichments,
          mappings,
          metadataTables,
          event
        )

        t.value = displayValue(value)

        if (_checkDestTypeIsCompatible(value, type) === null) {
          t.typeError = true
        } else {
          delete t.typeError
        }
      }
    }

    return t
  })

  const transformationsDestinations = transformations.map(t => t.destination)
  const enrichmentsPreview = (enrichments || [])
    .filter(e => !transformationsDestinations.includes(e.destination))
    .map(e => {
      if (e.value === undefined) {
        e.value = _getEnrichmentValue(e, mappings, metadataTables, event)
      }

      const type = dictionaryFields.find(({ name }) => name === e.destination)

      if (e.value !== undefined) {
        const error = !_mappingIsOK(e.value, type.type)

        if (error) {
          e.typeError = true
        } else {
          delete e.typeError
        }

        e.value = displayValue(e.value)
      }

      return e
    })

  const enrichmentsDestinations = enrichmentsPreview.map(t => t.destination)
  const mappingsPreview = (mappings || [])
    .filter(
      e =>
        ![...transformationsDestinations, ...enrichmentsDestinations].includes(
          e.destination
        )
    )
    .map(m => {
      const value = _searchPathValueInEvent(event, m.path)
      const type = dictionaryFields.find(({ name }) => name === m.destination)
      const error = !_mappingIsOK(value, type.type)

      if (error) {
        if (m.type === "TIMESTAMP") {
          m.tsIndexDateError = error
        } else {
          m.typeError = error
        }
      } else {
        delete m.tsIndexDateError
        delete m.typeError
      }

      delete m.value

      return {
        ...m,
        value: displayValue(value),
      }
    })

  return mappingsPreview
    .concat(enrichmentsPreview)
    .concat(transformationsPreview)
}

function displayValue(value) {
  return typeof value === "string" ? `"${value}"` : value
}

export function _mappingIsOK(value, type) {
  switch (typeof value) {
    case "string":
      return (
        type === "STRING" ||
        (type === "TIMESTAMP" && moment(value, moment.ISO_8601, true).isValid())
      )
    case "number":
      return type === "LONG" || type === "FLOAT" || type === "DOUBLE"
    default:
      return false
  }
}

export function _isValidPath(event, path) {
  if (path) {
    const value = _searchPathValueInEvent(event, path)

    return typeof value !== "object" && !Array.isArray(value)
  } else {
    return false
  }
}
