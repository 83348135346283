import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Modal, Form, Input } from "antd"

const { TextArea } = Input

const ModalJSONCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          title={this.props.intl.formatMessage({
            id: "modal-json-upload-title",
          })}
          okText={this.props.intl.formatMessage({ id: "insert" })}
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item
              label={this.props.intl.formatMessage({
                id: "modal-json-upload-field-label",
              })}
            >
              {getFieldDecorator("payload")(
                <TextArea
                  placeholder={this.props.intl.formatMessage({
                    id: "modal-json-upload-field-placeholder",
                  })}
                  autosize={{ minRows: 6 }}
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
      )
    }
  }
)

export default injectIntl(ModalJSONCreateForm)
