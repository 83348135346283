import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Icon, Input, Tag } from "antd"
import { TweenOneGroup } from "rc-tween-one"
import { useIntl } from "gatsby-plugin-intl"
import useStateObject from "../hooks/useStateObject"

const EditableTagGroup = ({
  initialValues,
  updateParent,
  addLabel,
  isValid,
  widthInput,
}) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    tags: [],
    inputVisible: false,
    inputValue: "",
  })
  const input = useRef(null)

  useEffect(() => {
    setState({ tags: initialValues || [] })
  }, [])

  const handleClose = removedTag => {
    const tags = state.tags.filter(tag => tag !== removedTag)
    setState({ tags })
    updateParent(tags)
  }

  const showInput = () => {
    setState({ inputVisible: true }, () => input.current.focus())
  }

  const handleInputChange = e => {
    const newValue = e.target.value
    setState({ inputValue: newValue })
  }

  const handleInputConfirm = () => {
    const { inputValue } = state
    let { tags } = state
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    setState({
      tags,
      inputVisible: false,
      inputValue: "",
    })
    updateParent(tags)
  }

  const saveInputRef = ref => (input.current = ref)

  const forMap = tag => {
    const valid = isValid ? isValid(tag) : true
    const tagElem = (
      <Tag
        closable
        color={valid ? "black" : "red"}
        onClose={e => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    )
  }

  const { tags, inputVisible, inputValue } = state
  const tagChild = tags.map(forMap)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <div style={{}}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
            onComplete: e => {
              e.target.style = ""
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {inputVisible && (
          <Input
            ref={saveInputRef}
            type="text"
            size="small"
            style={{ width: widthInput, marginTop: 10 }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            onClick={showInput}
            style={{
              background: "#fff",
              borderStyle: "dashed",
              marginTop: 10,
            }}
          >
            <Icon type="plus" />{" "}
            {addLabel || intl.formatMessage({ id: "add-tag" })}
          </Tag>
        )}
      </div>
    </div>
  )
}

EditableTagGroup.propTypes = {
  initialValues: PropTypes.array.isRequired,
  updateParent: PropTypes.func.isRequired,
  addLabel: PropTypes.string,
  isValid: PropTypes.func,
  widthInput: PropTypes.number,
}

EditableTagGroup.defaultProps = {
  widthInput: 100,
}

export default EditableTagGroup
