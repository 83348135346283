import React from "react"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Spin,
  Steps,
  Table,
  Tooltip,
  Upload,
} from "antd"
import ReactJson from "react-json-view"
import Papa from "papaparse"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import EditableTagGroup from "./editableTagGroup"
import authFetch from "../services/network"
import * as IngestService from "../services/ingest"
import { callbackRolesErrorMessages } from "../services/roles/rolesRequestErrorMessages"
import emailUtils from "../utils/email-utils"

const { TextArea } = Input
const { Option } = Select
const { Step } = Steps

class NewMetadataIngest extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.fileReader = null

    this.state = {
      currentStep: 0,
      name: props.ingest.name || "",
      alias: props.ingest.alias || "",
      description: props.ingest.description || "",
      email: this.getEmailValues(props.ingest.email),
      separator: props.ingest.separator || "",
      metadataIndex: props.ingest.metadataIndex || "",
      event: props.ingest.event || null,
      modalVisible: false,
      tags: ["METADATA"],
      dataSource: props.ingest.event
        ? this.getDataSourceFromEvent(props.ingest.event.event)
        : [],
      createIngestCheckboxChecked: false,
      roles: [],
      rolesSelected: props.ingest.roles || [],
      loading: true,
      editing: false,
      businessUnit: props.ingest.businessUnit || null,
      businessUnits: [],
    }

    this._renderCurrentStep = this._renderCurrentStep.bind(this)
    this._handleNameChange = this._handleNameChange.bind(this)
    this._handleAliasChange = this._handleAliasChange.bind(this)
    this._handleDescriptionChange = this._handleDescriptionChange.bind(this)
    this._handleBusinessUnitChange = this._handleBusinessUnitChange.bind(this)
    this._handleEmailChange = this._handleEmailChange.bind(this)
    this._handleSeparatorChange = this._handleSeparatorChange.bind(this)
    this._handleMetadataChange = this._handleMetadataChange.bind(this)
    this._handleUpload = this._handleUpload.bind(this)
    this._deleteExampleEvent = this._deleteExampleEvent.bind(this)
    this._onStepsStepChange = this._onStepsStepChange.bind(this)
    this._onFixTypeChange = this._onFixTypeChange.bind(this)
    this._onCreateIngestCheckboxChange = this._onCreateIngestCheckboxChange.bind(
      this
    )
    this.onSelectRole = this.onSelectRole.bind(this)
    this.onChangeRole = this.onChangeRole.bind(this)

    this._createIngest = this._createIngest.bind(this)
    this._onAddTag = this._onAddTag.bind(this)
    this._onAddEmail = this._onAddEmail.bind(this)
    this.___dummyRequest = this.___dummyRequest.bind(this)
    this.getEmailValidateStatus = this.getEmailValidateStatus.bind(this)
  }

  componentDidMount() {
    this.fileReader = new FileReader()
    this.fileReader.onload = event => {
      const rawData = event.target.result
      const content = Papa.parse(rawData, {
        dynamicTyping: true,
        header: true,
        preview: 1,
        skipEmptyLines: true,
      })

      const headers = (content.meta && content.meta.fields) || []
      const headersThatDoNotMatchRequirements = headers.filter(
        header =>
          !/^[a-z|A-Z|-|_]+(a-z|A-Z|0-9|-|_)*/.test(header) || / /g.test(header)
      )

      if (
        Array.isArray(headersThatDoNotMatchRequirements) &&
        headersThatDoNotMatchRequirements.length > 0
      ) {
        message.error(
          this._formatMessage("new-ingestion-csv-headers-error", {
            headers: headersThatDoNotMatchRequirements.join('", "'),
          })
        )
      } else {
        const event = content && Array.isArray(content.data) && content.data[0]
        let dataSource = []
        if (NewMetadataIngest._isObject(event)) {
          dataSource = this.getDataSourceFromEvent(event)
        }
        this.setState({ event }, () => this.setState({ dataSource }))
      }
    }

    const editing = !!(this.props.ingest && this.props.ingest.alias)
    this.setState({ editing })

    IngestService.getRoles()
      .then(roles => {
        this.setState({ roles: roles.map(role => role.name) })
        IngestService.getTakenAliases().then(ingestAliases => {
          this.setState({ ingestAliases })
          IngestService.getBusinessUnits().then(businessUnits =>
            this.setState({ businessUnits, loading: false })
          )
        })
      })
      .catch(() => {
        this.setState({ loading: false })
        callbackRolesErrorMessages(null, "LIST").then(text =>
          message.error(this.props.intl.formatMessage({ id: text }))
        )
      })
  }

  static displayValueWithTypes(value) {
    switch (typeof value) {
      case "boolean":
        return (
          <div>
            <pre className="inline">{value}</pre>
            <span>[Boolean]</span>
          </div>
        )
      case "string":
        return (
          <div>
            <pre className="inline">"{value}"</pre>
            <span>[String]</span>
          </div>
        )
      case "number":
        return (
          <div>
            <pre className="inline">{value}</pre>
            <span>[Number]</span>
          </div>
        )
    }
  }

  static displayValue(value) {
    switch (typeof value) {
      case "boolean":
        return (
          <div>
            <pre className="inline">{value.toString()}</pre>
          </div>
        )
      case "string":
        return (
          <div>
            <pre className="inline">"{value}"</pre>
          </div>
        )
      case "number":
        return (
          <div>
            <pre className="inline">{value}</pre>
          </div>
        )
    }
  }

  static _isObject(obj) {
    return !!(obj && typeof obj === "object" && obj.constructor === Object)
  }

  getDataSourceFromEvent(event) {
    return event
      ? Object.entries(event).map(([key, value]) => ({
          field: key,
          inferredValue: this._inferValue(value),
          fixResult: this._inferValue(value),
        }))
      : []
  }

  get TRANSFORMATIONS() {
    return [
      {
        value: "DONT_FIX",
        text: this._formatMessage("new-metadata-dont-fix-option"),
      },
      {
        value: "BOOLEAN",
        text: "BOOLEAN",
      },
      {
        value: "NUMBER",
        text: "NUMBER",
      },
      {
        value: "STRING",
        text: "STRING",
      },
    ]
  }

  get STEPS() {
    return [
      this._formatMessage("new-metadata-step-config"),
      this._formatMessage("new-metadata-step-event"),
      this._formatMessage("new-metadata-step-adjustments"),
    ]
  }

  get TYPE_FIX_TABLE_COLUMNS() {
    return [
      {
        title: this._formatMessage("new-metadata-csv-column"),
        dataIndex: "field",
        width: "15%",
        ellipsis: true,
      },
      {
        title: this._formatMessage("new-metadata-inferred-value-column"),
        dataIndex: "inferredValue",
        width: "30%",
        ellipsis: true,
        render: NewMetadataIngest.displayValueWithTypes,
      },
      {
        title: this._formatMessage("new-metadata-adjustment-column"),
        dataIndex: "fixType",
        width: "15%",
        ellipsis: true,
        render: (fixType, record) => (
          <Select
            style={{ width: "100%" }}
            defaultValue={fixType || this.TRANSFORMATIONS[0].value}
            onChange={this._onFixTypeChange(record)}
          >
            {this.TRANSFORMATIONS.map(({ value, text }) => (
              <Option value={value}>{text}</Option>
            ))}
          </Select>
        ),
      },
      {
        title: this._formatMessage("new-metadata-result-column"),
        dataIndex: "fixResult",
        width: "25%",
        ellipsis: true,
        render: NewMetadataIngest.displayValue,
      },
    ]
  }

  _inferValue(value) {
    return value instanceof Date ? value.toISOString() : value
  }

  _onFixTypeChange(record) {
    return fixType => {
      record.fixType = fixType
      record.fixResult = this._fixFieldForResult(record.inferredValue, fixType)

      this._updateRecord(record)
    }
  }

  _updateRecord(newRecord) {
    let index = 0
    const { dataSource } = this.state
    while (index < dataSource.length) {
      if (dataSource[index].field === newRecord.field) {
        dataSource[index] = newRecord
        this.setState({ dataSource })
        break
      }
      index++
    }
    if (index >= dataSource.length) {
      console.error(
        '[ERROR] Cannot find "oldRecord" inside dataSource for type fixing'
      )
    }
  }

  _fixFieldForResult(value, newType) {
    try {
      switch (newType) {
        case "BOOLEAN":
          return !!value
        case "NUMBER":
          return Number(value)
        case "STRING":
          return value === null ? "" : value.toString()
        case "DONT_FIX":
          return value
      }
    } catch (ex) {
      console.error(
        `[ERROR]: "_fixFieldForResult" error casting ${value} into ${newType}. ${ex}`
      )
      message.error(
        this._formatMessage("new-metadata-cant-transform-into-error", {
          value: value,
          newType: newType,
        })
      )
      return value
    }
  }

  _handleNameChange(ev) {
    this.setState({ name: ev.currentTarget.value })
  }

  _handleAliasChange(ev) {
    this.setState({ alias: ev.currentTarget.value })
  }

  _handleDescriptionChange(ev) {
    this.setState({ description: ev.currentTarget.value })
  }

  _handleBusinessUnitChange(value) {
    this.setState({ businessUnit: value })
  }

  _handleEmailChange(ev) {
    this.setState({ email: ev.currentTarget.value })
  }

  _handleSeparatorChange(ev) {
    this.setState({ separator: ev.currentTarget.value })
  }

  _handleMetadataChange(ev) {
    this.setState({ metadataIndex: ev.currentTarget.value })
  }

  onChangeRole(rolesSelected) {
    this.setState({ rolesSelected })
  }

  onSelectRole(value) {
    if (value === "select-all") {
      this.onChangeRole(this.state.roles.slice())
    } else if (value === "clear") {
      this.onChangeRole([])
    }
  }

  _onStepsStepChange(newStep) {
    if (newStep <= this.state.currentStep) {
      this.setState({ currentStep: newStep })
    } else {
      this._goToStep(newStep)
    }
  }

  _goToStep(newStep) {
    this._validateCurrentStep().then(_ =>
      this.setState({ currentStep: newStep })
    )
  }

  _validateCurrentStep() {
    switch (this.state.currentStep) {
      case 0:
        return this._validateConfigStep()
      case 1:
        return this._validateEventExampleStep()
      default:
        return
    }
  }

  _validateConfigStep() {
    return new Promise((ok, ko) =>
      this.props.form.validateFields(
        ["name", "alias", "description", "businessUnit"],
        err => {
          const emailsValid = this.getEmailValidateStatus() === ""
          err || !emailsValid ? ko() : ok()
        }
      )
    )
  }

  _validateEventExampleStep() {
    const eventExamplePromise =
      !NewMetadataIngest._isObject(this.state.event) ||
      Object.keys(this.state.event).length === 0
        ? message.error(
            this._formatMessage("new-metadata-event-is-mandatory")
          ) && Promise.reject()
        : Promise.resolve()

    return Promise.all([
      new Promise((ok, ko) =>
        this.props.form.validateFields(["metadataIndex", "separator"], err =>
          err ? ko() : ok()
        )
      ),
      eventExamplePromise,
    ])
  }

  _onAddTag(tags = []) {
    this.setState({ tags })
  }

  _onAddEmail(email = []) {
    this.setState({ email })
  }

  _deleteExampleEvent() {
    this.setState({ event: null, dataSource: [] })
  }

  // https://medium.com/@mattcroak718/managing-file-uploads-with-ant-design-6d78e592f2c4
  ___dummyRequest({ onSuccess }) {
    setTimeout(() => onSuccess("ok"), 0)
  }

  _handleUpload(data) {
    if (data.file.status === "done") {
      try {
        this.fileReader.readAsText(data.file.originFileObj)
      } catch (ex) {
        message.error(ex)
      }
    }
  }

  _openBeforeFileUploadModal() {
    this.setState({ modalVisible: true })
  }

  _getDataTypeFromInferredValue(inferredValue) {
    switch (typeof inferredValue) {
      case "string":
        return "STRING"
      case "number":
        return "DOUBLE"
      case "boolean":
        return "BOOLEAN"
    }
  }

  _getOutputTypeForPipelineFromDataSource(dataSource) {
    const { fixType, inferredValue } = dataSource

    if (typeof fixType === "undefined" || fixType === "DONT_FIX") {
      return this._getDataTypeFromInferredValue(inferredValue)
    } else if (fixType === "NUMBER") {
      return "DOUBLE"
    } else {
      return fixType
    }
  }

  _buildNewIngestRequestBody() {
    const {
      name,
      alias,
      description,
      email,
      metadataIndex,
      separator,
      rolesSelected,
      tags,
      event,
      businessUnit,
    } = this.state
    const pipeline = (this.state.dataSource || []).map((ds, index) => {
      return {
        origin: ds.field,
        destination: ds.field,
        outputType: this._getOutputTypeForPipelineFromDataSource(ds),
        order: index,
        operator: "parse",
        type: "transformation",
      }
    })

    return {
      name,
      alias,
      description,
      tags,
      roles: rolesSelected,
      sampleEvent: {
        separator,
        filetype: "CSV",
        payload: event,
      },
      setup: {
        email,
        metadataIndex,
        type: "METADATA",
      },
      pipeline,
      businessUnit,
    }
  }

  _createIngest() {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: this._buildNewIngestRequestBody(),
    }).then(response => {
      const status = response.status
      if (status !== 200 && status !== 201) {
        console.error(
          `[ERROR]: Saving new metadata ingest. Error Code: ${status}\n${(response &&
            response.message) ||
            response}`
        )
        message.error(this._formatMessage("ingestion-activate-ko"))
      } else {
        response.json().then(newIngestData => {
          message.info(this._formatMessage("ingestion-activate-ok"))
          setTimeout(() => {
            navigate(`/ingest/creating?id=${newIngestData.id}&t=METADATA`)
          }, 1500)
        })
      }
    })
  }

  _onCreateIngestCheckboxChange(ev) {
    this.setState({ createIngestCheckboxChecked: ev.target.checked })
  }

  _renderEventExampleStep() {
    const { getFieldDecorator } = this.props.form

    return (
      <Form>
        {this.state.event === null ? (
          <Form.Item label={this._formatMessage("upload-csv-label")}>
            <div>
              <Upload
                id="file"
                type="file"
                name="eventFile"
                accept=".csv"
                listType="text"
                multiple={false}
                showUploadList={false}
                customRequest={this.___dummyRequest}
                onChange={this._handleUpload}
              >
                <Button>
                  <Icon type="upload" />{" "}
                  {this._formatMessage("click-to-upload-csv")}
                </Button>
              </Upload>
            </div>
            <b style={{ display: "block", marginTop: "20px" }}>
              {this._formatMessage("csv-file-restrictions-header")}
            </b>
            <ul
              className="metadata-index-indications"
              style={{ paddingTop: 0 }}
              dangerouslySetInnerHTML={{
                __html: this._formatMessage("csv-file-restrictions"),
              }}
            />
          </Form.Item>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>{this._formatMessage("example-event-label")}:</h5>
              <button
                title={this._formatMessage("delete-example-event-title")}
                className="button-no-styles"
                onClick={this._deleteExampleEvent}
              >
                <Icon
                  type="delete"
                  size="large"
                  style={{
                    marginRight: "5px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
            <ReactJson
              src={this.state.event}
              sortKeys={true}
              name={false}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
              style={{
                marginBottom: "1rem",
                maxHeight: "200px",
                overflow: "auto",
              }}
            />
          </>
        )}
        <Form.Item
          label={this._formatMessage("csv-delimiter-label")}
          layout="horizontal"
        >
          {getFieldDecorator("separator", {
            initialValue: this.state.separator,
            rules: [
              {
                required: true,
                message: this._formatMessage("csv-delimiter-required"),
              },
            ],
          })(
            <Input
              name="separator"
              onChange={this._handleSeparatorChange}
              placeholder={this._formatMessage("csv-delimiter-placeholder")}
            />
          )}
        </Form.Item>
        <Form.Item
          label={this._formatMessage("csv-index-label")}
          layout="horizontal"
        >
          {getFieldDecorator("metadataIndex", {
            initialValue: this.state.metadataIndex,
            rules: [
              {
                required: true,
                message: this._formatMessage("csv-index-required"),
              },
            ],
          })(
            <Input
              name="metadataIndex"
              onChange={this._handleMetadataChange}
              placeholder={this._formatMessage("csv-index-placeholder")}
            />
          )}
        </Form.Item>
        <div
          dangerouslySetInnerHTML={{
            __html: this._formatMessage("csv-index-restrictions"),
          }}
        />
        <Button
          type="primary"
          style={{ marginTop: 10 }}
          onClick={() => this._goToStep(2)}
        >
          {this._formatMessage("next-step")}
        </Button>
      </Form>
    )
  }

  getEmailValues(email) {
    if (email) {
      if (Array.isArray(email) && email.length) {
        return email
      } else if (typeof email === "string") {
        return email.split(",")
      }
    }
    return []
  }

  getEmailValidateStatus() {
    if (!this.state.email || !this.state.email.length) {
      return this._formatMessage("new-ingestion-email-required")
    } else {
      let emailsValid = true
      let i = 0
      while (emailsValid && i < this.state.email.length) {
        emailsValid = emailUtils.isValidEmail(this.state.email[i])
        i++
      }
      if (!emailsValid) {
        return this._formatMessage("new-ingestion-email-not-valid-advise")
      }
    }
    return ""
  }

  _renderConfigurationStep() {
    const { getFieldDecorator } = this.props.form
    const initialTagValues =
      Array.isArray(this.state.tags) && this.state.tags.length
        ? this.state.tags
        : [this.props.type]
    const emailValidateStatus = this.getEmailValidateStatus()

    return (
      <Form>
        <Form.Item
          label={this._formatMessage("new-ingestion-name-label")}
          layout="horizontal"
        >
          {getFieldDecorator("name", {
            initialValue: this.state.name,
            rules: [
              {
                required: true,
                message: this._formatMessage("new-ingestion-name-required"),
              },
            ],
          })(
            <Input
              name="name"
              onChange={this._handleNameChange}
              placeholder={this._formatMessage(
                "new-ingestion-name-placeholder"
              )}
            />
          )}
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  <span style={{ marginRight: "5px" }}>
                    {this._formatMessage("new-ingestion-alias-placeholder")}
                  </span>
                  <Tooltip
                    title={this._formatMessage("new-ingestion-alias-tooltip")}
                  >
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                </span>
              }
              layout="horizontal"
            >
              {getFieldDecorator("alias", {
                initialValue: this.state.alias,
                rules: [
                  {
                    required: true,
                    message: this._formatMessage(
                      "new-ingestion-alias-required"
                    ),
                  },
                  {
                    max: 60,
                    message: this._formatMessage(
                      "new-ingestion-alias-max-length"
                    ),
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (!this.state.editing) {
                        try {
                          if (/[`~,.<>;':"/[\]|{}()=_+\s]/g.test(value)) {
                            throw new Error(
                              this._formatMessage(
                                "new-ingestion-alias-regex-advise"
                              )
                            )
                          } else if (
                            (this.state.ingestAliases || []).includes(value)
                          ) {
                            throw new Error(
                              this._formatMessage(
                                "new-ingestion-alias-already-exists"
                              )
                            )
                          } else {
                            cb()
                          }
                        } catch (err) {
                          cb(err)
                        }
                      } else {
                        cb()
                      }
                    },
                  },
                ],
              })(
                <Input
                  name="alias"
                  disabled={this.state.editing || !this.state.ingestAliases}
                  onChange={this._handleAliasChange}
                  placeholder={
                    Array.isArray(this.state.ingestAliases) &&
                    this.state.ingestAliases.length
                      ? this._formatMessage("new-ingestion-alias-placeholder")
                      : this._formatMessage("new-ingestion-alias-loading")
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={this._formatMessage("new-ingestion-description-label")}
          layout="horizontal"
        >
          {getFieldDecorator("description", {
            initialValue: this.state.description,
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-description-required"
                ),
              },
            ],
          })(
            <TextArea
              name="description"
              onChange={this._handleDescriptionChange}
              placeholder={this._formatMessage(
                "new-ingestion-description-placeholder"
              )}
              style={{ minHeight: "5.6em" }}
            />
          )}
        </Form.Item>
        <Form.Item
          label={this._formatMessage("new-ingestion-business-unit")}
          layout="horizontal"
        >
          {getFieldDecorator("businessUnit", {
            initialValue: this.state.businessUnit,
            rules: [
              {
                required: true,
                message: this._formatMessage(
                  "new-ingestion-business-unit-required"
                ),
              },
            ],
          })(
            <Select
              name="business-unit"
              aria-label="business-unit"
              onChange={this._handleBusinessUnitChange}
              style={{ width: "100%" }}
              value={this.state.businessUnit}
            >
              {this.state.businessUnits.map(p => (
                <Option key={p.value} value={p.value}>
                  {p.text}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={this._formatMessage("new-ingestion-email-label")}
          layout="horizontal"
          validateStatus={emailValidateStatus ? "error" : "success"}
          help={emailValidateStatus}
        >
          <EditableTagGroup
            initialValues={this.state.email}
            updateParent={this._onAddEmail}
            addLabel={this._formatMessage("add-email")}
            isValid={emailUtils.isValidEmail}
            widthInput={200}
          />
        </Form.Item>
        <Form.Item label={this._formatMessage("new-ingestion-roles-intro")}>
          <Select
            showSearch={true}
            mode="multiple"
            placeholder={this._formatMessage("new-ingestion-roles-placeholder")}
            value={this.state.rolesSelected}
            loading={this.state.roles && this.state.roles.length === 0}
            name="select-ingest"
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={this.onChangeRole}
            onSelect={this.onSelectRole}
          >
            <Option key="select-all" value="select-all">
              {this._formatMessage("new-ingestion-roles-select-all-option")}
            </Option>
            <Option key="clear" value="clear">
              {this._formatMessage("new-ingestion-roles-clear-all-option")}
            </Option>
            {Array.isArray(this.state.roles)
              ? this.state.roles.map(d => (
                  <Option key={d} value={d}>
                    {d}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label={this._formatMessage("new-ingestion-tags-label")}
          layout="horizontal"
        >
          <EditableTagGroup
            name="tags"
            initialValues={initialTagValues}
            updateParent={this._onAddTag}
          />
        </Form.Item>
        <Button
          type="primary"
          style={{ marginTop: 10 }}
          onClick={() => this._goToStep(1)}
        >
          {this._formatMessage("next-step")}
        </Button>
      </Form>
    )
  }

  _renderFixTypeInferenceStep() {
    const { dataSource = [] } = this.state

    return (
      <>
        <p
          dangerouslySetInnerHTML={{
            __html: this._formatMessage(
              "new-metadata-ingestion-adjustments-help"
            ),
          }}
        />
        <Table
          rowKey="field"
          className="ingests-table"
          columns={this.TYPE_FIX_TABLE_COLUMNS}
          dataSource={Array.isArray(dataSource) ? dataSource : [dataSource]}
        />
        <h5>{this._formatMessage("new-ingestion-create")}</h5>
        <div>
          <div style={{ marginBottom: "1rem" }}>
            <Checkbox
              checked={this.state.createIngestCheckboxChecked}
              onChange={this._onCreateIngestCheckboxChange}
            >
              {this._formatMessage("new-ingestion-create-advice")}{" "}
              <a href="/help/pricing" target="_blank">
                {this._formatMessage("new-ingestion-more-info")}
              </a>
              .
            </Checkbox>
          </div>
          <Button
            type="primary"
            disabled={!this.state.createIngestCheckboxChecked}
            onClick={this._createIngest}
          >
            {this._formatMessage("new-ingestion-create")}
          </Button>
        </div>
      </>
    )
  }

  _renderCurrentStep() {
    switch (this.state.currentStep) {
      case 0:
        return this._renderConfigurationStep()
      case 1:
        return this._renderEventExampleStep()
      case 2:
        return this._renderFixTypeInferenceStep()
      default:
        return null
    }
  }

  _getStepTitle(step, title) {
    return step === this.state.currentStep ? (
      <div style={{ color: "#5731F9" }}>{title}</div>
    ) : (
      title
    )
  }

  _getStepDescription(step) {
    return step === this.state.currentStep ? (
      <div style={{ borderBottom: "1px solid #27D098", width: 20 }} />
    ) : null
  }

  render() {
    if (this.state.loading) {
      return (
        <Row
          type="flex"
          justify="center"
          style={{ marginTop: 40, padding: "20px 30px" }}
        >
          <Spin size="large" />
        </Row>
      )
    }

    return (
      <div className="content">
        <Row
          type="flex"
          justify="center"
          align="top"
          style={{ marginBottom: "36px" }}
        >
          <Col span={24}>
            <Steps
              size="small"
              current={this.state.currentStep}
              direction="horizontal"
              onChange={this._onStepsStepChange}
            >
              {this.STEPS.map((title, index) => (
                <Step
                  key={title}
                  title={this._getStepTitle(index, title)}
                  description={this._getStepDescription(index)}
                />
              ))}
            </Steps>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="top"
          style={{ marginBottom: "20px" }}
        >
          <Col span={16}>{this._renderCurrentStep()}</Col>
        </Row>
      </div>
    )
  }
}

export default injectIntl(
  Form.create({ name: "new_metadata_ingest_form" })(NewMetadataIngest)
)
