import React from "react"
import { Icon } from "antd"
import { useIntl } from "gatsby-plugin-intl"
import ReactJson from "react-json-view"

const JsonExampleDisplay = ({ onDeleteClick, event }) => {
  const intl = useIntl()

  const deleteExampleEvent = () => {
    typeof onDeleteClick === "function" && onDeleteClick()
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5>{intl.formatMessage("example-event-label")}:</h5>
        <button
          title={intl.formatMessage("delete-example-event-title")}
          className="button-no-styles"
          onClick={deleteExampleEvent}
        >
          <Icon
            type="delete"
            size="large"
            style={{
              marginRight: "5px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          />
        </button>
      </div>
      <ReactJson
        src={event}
        sortKeys={true}
        name={false}
        displayObjectSize={false}
        displayDataTypes={false}
        enableClipboard={false}
        style={{
          marginBottom: "1rem",
          maxHeight: "200px",
          overflow: "auto",
        }}
      />
    </>
  )
}

export default JsonExampleDisplay
