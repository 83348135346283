import React from "react"

import IngestRtNrtEventForm from "./ingestRtNrtEventForm"
import IngestBatchEventForm from "./ingestBatchEventForm"
import { INGEST_TYPES } from "../../services/constants"

const IngestEventForm = ({
  type,
  publicationsPerSecondLimit,
  publicationsPerSecond,
  event,
  separator,
  headersProvidedSeparately,
  headersOrder,
  onEventChange,
  onPublicationsPerSecondChange,
  onSeparatorChange,
  onHeadersProvidedSeparatelyChange,
  onNextStep,
}) => {
  const handleEventChange = event => {
    onEventChange(event)
  }

  const handlePublicationsPerSecondChange = value => {
    onPublicationsPerSecondChange(value)
  }

  const handleSeparatorChange = value => {
    onSeparatorChange(value)
  }

  const handleHeadersProvidedSeparatelyChange = value => {
    onHeadersProvidedSeparatelyChange(value)
  }

  const handleNextStep = () => {
    onNextStep()
  }

  return [INGEST_TYPES["rt"], INGEST_TYPES["nrt"]].includes(type) ? (
    <IngestRtNrtEventForm
      publicationsPerSecondLimit={publicationsPerSecondLimit}
      publicationsPerSecond={publicationsPerSecond}
      event={event && event.event}
      onEventChange={handleEventChange}
      onPublicationsPerSecondChange={handlePublicationsPerSecondChange}
      onNextStep={handleNextStep}
    />
  ) : (
    <IngestBatchEventForm
      event={event}
      separator={separator}
      headersProvidedSeparately={headersProvidedSeparately}
      headersOrder={headersOrder}
      onEventChange={handleEventChange}
      onSeparatorChange={handleSeparatorChange}
      onHeadersProvidedSeparatelyChange={handleHeadersProvidedSeparatelyChange}
      onNextStep={handleNextStep}
    />
  )
}

export default IngestEventForm
