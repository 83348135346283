import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import AceEditor from "react-ace"
import moment from "moment-timezone"
import _ from "lodash"
import {
  Button,
  Col,
  Collapse,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  message,
} from "antd"

import TimezoneSelector from "../timezoneSelector"
import authFetch from "../../services/network"

import * as MappingsHelper from "../../services/ingest/mappings"

const { Option } = Select
const { TabPane } = Tabs
const { Panel } = Collapse

const DICTIONARY_HIGHLIGHT_CLASS = "dict-mapped"
const MAPPING_TYPE_ERROR_CLASS = "dict-mapping-error"

const FUNCTION_EXAMPLE = `function(fieldValue, event) {
  /* Your code here */
}
`

/**
 * This component might be divided in the future as new features are added (alarms, validations).
 * Due to time limitations, all current features are kept here (in the same component)
 * Current Features -> Enrichments & Transformations
 */

const CASTING_ERROR = null
const GENERIC_CASTINGS = [
  {
    label: "FLOAT",
    value: "float",
  },
  {
    label: "LONG",
    value: "long",
  },
  {
    label: "DOUBLE",
    value: "double",
  },
  {
    label: "STRING",
    value: "string",
  },
]

class IngestOthers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dictionaryFields: [],
      preloadedMetadataTables: [],
      metadataTables: [],
      enrichmentTable: [],
      referenceMetadata: "",
      currentEnrichment: {},
      currentTransformation: null,
      dateFormat: "",
      dateOffset: 0,
      displayTransformation: {},
      selectedTab: undefined,
    }

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this._getEventForAdvancedTransformations = this._getEventForAdvancedTransformations.bind(
      this
    )
    this._originAndDestinationAreValid = this._originAndDestinationAreValid.bind(
      this
    )
    this._handleSelectEnrich = this._handleSelectEnrich.bind(this)
    this._handleChangeEnrichmentField = this._handleChangeEnrichmentField.bind(
      this
    )
    this._handleChangeEnrichmentTable = this._handleChangeEnrichmentTable.bind(
      this
    )
    this._handlePreSelectToEnrich = this._handlePreSelectToEnrich.bind(this)
    this._onGenericCastingSelect = this._onGenericCastingSelect.bind(this)
    this._renderColumnWithTooltip = this._renderColumnWithTooltip.bind(this)
    this._onSelectDictonaryFieldForTransformation = this._onSelectDictonaryFieldForTransformation.bind(
      this
    )
    this._onCollapseChange = this._onCollapseChange.bind(this)
    this._confirmGenericCasting = this._confirmGenericCasting.bind(this)
    this._onDateCastingInput = this._onDateCastingInput.bind(this)
    this._onTimezoneChange = this._onTimezoneChange.bind(this)
    this._confirmDateCasting = this._confirmDateCasting.bind(this)
    this._onCustomTransformationChange = this._onCustomTransformationChange.bind(
      this
    )
    this._confirmCustomTransformation = this._confirmCustomTransformation.bind(
      this
    )
    this._generatePreview = this._generatePreview.bind(this)
    this._renderTransformationsTab = this._renderTransformationsTab.bind(this)
  }

  _onCollapseChange(selectedTab) {
    this.setState({ selectedTab })
  }

  componentDidMount() {
    const { mappings = [], enrichments } = this.props

    if (Array.isArray(enrichments) && enrichments.length) {
      MappingsHelper.getMetadataTablesInUse(
        enrichments,
        mappings,
        this.props.event
      ).then(preloadedMetadataTables => {
        this.setState({ preloadedMetadataTables })
      })
    }
  }

  get COLUMNS_MODAL_MAPPING_ENRICHMENT() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-type"),
        dataIndex: "type",
        key: "type",
      },
    ]
  }

  get COLUMNS_MODAL_MAPPING_PREVIEW() {
    return [
      {
        title: this._formatMessage(
          "new-ingestion-tables-column-header-destination"
        ),
        dataIndex: "destination",
        key: "destination",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-value"),
        dataIndex: "value",
        key: "value",
        render: this._renderColumnWithTooltip,
      },
    ]
  }

  get COLUMNS_MODAL_ENRICHMENT() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-value"),
        dataIndex: "value",
        key: "value",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-type"),
        dataIndex: "type",
        key: "type",
      },
    ]
  }

  get COLUMNS_MODAL_MAPPING_FROM_TRANSFORMATION() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-type"),
        dataIndex: "type",
        key: "type",
      },
    ]
  }

  get COLUMNS_TRANSFORMATION_PREVIEW() {
    return [
      {
        title: this._formatMessage("new-ingestion-tables-column-header-field"),
        dataIndex: "destination",
        key: "destination",
      },
      {
        title: this._formatMessage("new-ingestion-tables-column-header-value"),
        dataIndex: "value",
        key: "value",
        render: this._renderColumnWithTooltip,
      },
    ]
  }

  _handlePreSelectToEnrich(record) {
    const enrichmentTable = this.state.enrichmentTable
    const index = enrichmentTable.findIndex(e => e.name === record.name)

    enrichmentTable.forEach(e => delete e.mapped)
    enrichmentTable[index].mapped = true

    this.setState({
      currentEnrichment: {
        origin: record.name,
        value: record.value,
      },
      enrichmentTable,
    })
  }

  _onEnrichmentDictionaryFieldClick(record) {
    const enrichmentForField = this.props.enrichments.find(
      e => e.destination === record.name
    )

    if (typeof enrichmentForField !== "undefined") {
      const enrichmentSearchValue = enrichmentForField.referenceDictionary
      const enrichmentTableName = enrichmentForField.metadataTable

      this.setState({ enrichmentTableName, enrichmentSearchValue }, () =>
        this._getEnrichmentTable(enrichmentTableName, enrichmentSearchValue)
      )
    } else {
      this.setState({
        enrichmentTableName: undefined,
        enrichmentSearchValue: undefined,
        enrichmentTable: [],
      })
    }
  }

  _handleSelectEnrich(record) {
    if (this.state.currentEnrichment && this.state.currentEnrichment.origin) {
      const { name } = record
      const field = (this.props.dictionaryFields || []).find(
        d => d.name === record.name
      )
      const newEnrichment = {
        name: record.name,
        referenceMetadata: this.state.referenceMetadata,
        metadataTable: this.state.enrichmentTableName,
        destination: name,
        referenceDictionary: this.state.enrichmentSearchValue,
        type: field.type,
      }
      const currentEnrichment = this.state.currentEnrichment
      const enrichments = (this.props.enrichments || []).slice()
      const enrichmentPosition = enrichments.findIndex(
        item => item.destination === record.name
      )

      enrichments.forEach(e => delete e.mapped)

      const enrichment = Object.assign(
        {},
        { ...newEnrichment },
        { ...currentEnrichment }
      )

      if (enrichmentPosition >= 0) {
        enrichments[enrichmentPosition] = enrichment
      } else {
        enrichments.push(enrichment)
      }

      this.setState(
        {
          currentEnrichment: null,
        },
        () => this._updateEnrichments(enrichments)
      )

      setTimeout(() => {
        const enrichmentTable = this.state.enrichmentTable

        enrichmentTable.forEach(e => delete e.mapped)

        this.setState({
          enrichmentTable,
        })
      }, 1000)
    } else {
      this._onEnrichmentDictionaryFieldClick(record)
    }
  }

  _getTypeOfEnrichmentValue(enrichmentValue) {
    if (enrichmentValue === null) {
      return "NULL"
    } else {
      switch (typeof enrichmentValue) {
        case "number":
          return "DOUBLE"
        case "boolean":
          return "BOOLEAN"
        case "string":
        default:
          return "STRING"
      }
    }
  }

  _getEnrichmentTable(table, referenceDictionary) {
    const mapping = (this.props.mappings || []).filter(
      m => m.destination === referenceDictionary
    )
    const path = mapping[0] !== undefined ? mapping[0].path : ""

    if (path) {
      const value = MappingsHelper._searchPathValueInEvent(
        this.props.event,
        path
      )

      authFetch(
        `${process.env.GATSBY_CONF_API_URL}/enrichments/${table}/?q=${value}`
      ).then(response => {
        if (response.status === 404) {
          message.warning(
            this._formatMessage("ingest-other-step-no-metadata-table")
          )
        } else if (response.status !== 200) {
          message.error(
            this._formatMessage(
              "ingest-other-step-error-retrieving-metadata-table"
            )
          )
          console.error(`[ERROR]: Retrieving metadata table: ${response}`)
          return Promise.reject(
            `[ERROR]: Retrieving metadata table: ${response}`
          )
        } else {
          return response
            .json()
            .then(({ item = {} }) => {
              const enrichmentTable = Object.entries(item).map(entry => ({
                name: entry[0],
                value: entry[1],
                type: MappingsHelper._getTypeOfEnrichmentValue(entry[1]),
              }))
              const metadataTables = this.props.metadataTables
              let referenceMetadata = ""

              for (let i = 0; i < metadataTables.length; i++) {
                const keys = Object.keys(metadataTables[i])

                for (let j = 0; j < keys.length; j++) {
                  if (table === keys[j]) {
                    referenceMetadata = metadataTables[i][keys[j]]
                    break
                  }
                }
              }

              this.setState({ enrichmentTable, referenceMetadata })
            })
            .catch(reason => {
              console.error(`[ERROR]: Processing list of entities\n${reason}`)
              this.setState({ enrichmentTable: [], referenceMetadata: "" })
            })
        }
      })
    } else {
      // if !path
      this.setState({ enrichmentTable: [], referenceMetadata: "" })
    }
  }

  _handleChangeEnrichmentField(enrichmentSearchValue) {
    this.setState({ enrichmentSearchValue }, () => {
      if (enrichmentSearchValue && this.state.enrichmentTableName) {
        this._getEnrichmentTable(
          this.state.enrichmentTableName,
          enrichmentSearchValue
        )
      }
    })
  }

  _handleChangeEnrichmentTable(enrichmentTableName) {
    this.setState({ enrichmentTableName }, () => {
      if (enrichmentTableName && this.state.enrichmentSearchValue) {
        this._getEnrichmentTable(
          enrichmentTableName,
          this.state.enrichmentSearchValue
        )
      }
    })
  }

  _onSelectDictonaryFieldForTransformation(dictionaryField) {
    const { name } = dictionaryField
    const transformationThatWillBeUpdated = (
      this.props.transformations || []
    ).find(({ destination }) => destination === name)

    let displayTransformation = {}
    let currentTransformation
    let selectedTab

    if (transformationThatWillBeUpdated) {
      const { operator, outputType } = transformationThatWillBeUpdated

      displayTransformation = {
        operator,
        outputType,
      }

      if (operator === "parse") {
        if (outputType === "TIMESTAMP") {
          selectedTab = "2"
          displayTransformation.dateFormat =
            transformationThatWillBeUpdated.dateFormat
        } else {
          selectedTab = "1"
          displayTransformation.outputType = outputType.toLowerCase()
        }
      } else if (operator === "eval") {
        displayTransformation.sourceCode =
          transformationThatWillBeUpdated.sourceCode
        selectedTab = "3"
      }
      currentTransformation = Object.assign({}, transformationThatWillBeUpdated)
    } else {
      currentTransformation = {
        destination: name,
        origin: name,
      }
    }

    const dictionaryFields = this.props.dictionaryFields.slice().map(field => {
      if (field.mapped) {
        delete field.mapped
      }

      if (field.name === name) {
        field.mapped = true
      }

      return field
    })

    this.setState({
      currentTransformation,
      dictionaryFields,
      displayTransformation,
      selectedTab,
    })
  }

  _onGenericCastingSelect(casting) {
    const displayTransformation = Object.assign(
      {},
      this.state.displayTransformation
    )

    if (displayTransformation) {
      displayTransformation.outputType = casting
    }

    this.setState({ casting, displayTransformation })
  }

  _originAndDestinationAreValid() {
    if (!this.state.currentTransformation) {
      return false
    } else {
      const { origin, destination } = this.state.currentTransformation

      return origin && destination
    }
  }

  _onDateCastingInput(event) {
    this.setState({
      dateFormat: event && event.currentTarget && event.currentTarget.value,
      displayTransformation: {
        dateFormat: event && event.currentTarget && event.currentTarget.value,
      },
    })
  }

  _confirmDateCasting() {
    if (
      this.state.currentTransformation &&
      this.state.currentTransformation.origin
    ) {
      const currentTransformation = this.state.currentTransformation

      currentTransformation.operator = "parse"
      currentTransformation.outputType = "TIMESTAMP"
      currentTransformation.dateFormat = this.state.dateFormat || ""
      currentTransformation.offset = this.state.dateOffset || 0

      const mapping = (this.props.mappings || []).find(
        m => m.destination === currentTransformation.destination
      )
      const enrichment = (this.props.enrichments || []).find(
        e => e.destination === currentTransformation.destination
      )
      const field = (this.props.dictionaryFields || []).find(
        d => d.name === mapping.destination
      )
      let initialValue

      if (enrichment || mapping) {
        if (enrichment) {
          initialValue =
            enrichment.value ||
            MappingsHelper._getEnrichmentValue(
              enrichment,
              this.props.mappings,
              this.state.preloadedMetadataTables,
              this.props.event
            )
        } else if (mapping) {
          initialValue = MappingsHelper._searchPathValueInEvent(
            this.props.event,
            mapping.path
          )
        }

        const castedValue = MappingsHelper._applyDateCasting(
          initialValue,
          this.state.dateFormat,
          this.state.dateOffset
        )

        if (
          castedValue === "Invalid date" ||
          castedValue === CASTING_ERROR ||
          !moment(castedValue, moment.ISO_8601, true).isValid()
        ) {
          if (field && field.property === "TS_INDEX") {
            currentTransformation.tsIndexDateError = true
          } else {
            currentTransformation.dateError = true
          }

          currentTransformation.value = `"${initialValue}"`
        } else {
          delete currentTransformation.tsIndexDateError
          delete currentTransformation.dateError

          currentTransformation.value = `"${castedValue}"`
        }
      } else {
        currentTransformation.mappingRequiredError = true
        currentTransformation.value = `[ERROR]`
      }

      const transformations = this.props.transformations.slice()
      const index = transformations.findIndex(
        t => t.destination === currentTransformation.destination
      )

      if (index >= 0) {
        transformations[index] = currentTransformation
      } else {
        transformations.push(currentTransformation)
      }

      this.setState(
        {
          currentTransformation: null,
        },
        () => this._updateTransformations(transformations)
      )
    }
  }

  _onTimezoneChange(offset) {
    const [hours, minutes] = offset.replace(/[-|+]/, "").split(":")
    const dateOffsetSign = /^-/.test(offset) ? -1 : 1
    const dateOffset = (Number(hours) * 60 + Number(minutes)) * dateOffsetSign

    this.setState({ dateOffset })
  }

  _confirmGenericCasting() {
    if (this._originAndDestinationAreValid() && this.state.casting) {
      const currentTransformation = Object.assign(
        {},
        this.state.currentTransformation
      )

      currentTransformation.outputType = this.state.casting.toUpperCase()
      currentTransformation.operator = "parse"

      const mapping = (this.props.mappings || []).find(
        t => t.destination === currentTransformation.destination
      )
      const enrichment = (this.props.enrichments || []).find(
        e => e.destination === currentTransformation.destination
      )
      let value

      if (enrichment || (mapping && mapping.path)) {
        if (enrichment) {
          value =
            enrichment.value ||
            MappingsHelper._getEnrichmentValue(
              enrichment,
              this.props.mappings,
              this.state.preloadedMetadataTables,
              this.props.event
            )
        } else if (mapping && mapping.path) {
          value = MappingsHelper._searchPathValueInEvent(
            this.props.event,
            mapping.path
          )
        }

        if (typeof value !== "undefined") {
          value = MappingsHelper._applyGenericCasting(
            value,
            currentTransformation.outputType
          )
        }

        currentTransformation.value = value

        delete currentTransformation.mappingRequiredError
      } else {
        currentTransformation.mappingRequiredError = true
      }

      const transformations = this.props.transformations.slice()
      const index = this.props.transformations.findIndex(
        t => t.destination === currentTransformation.destination
      )

      if (index >= 0) {
        transformations[index] = currentTransformation
      } else {
        transformations.push(currentTransformation)
      }

      this.setState(
        {
          currentTransformation: null,
        },
        () => this._updateTransformations(transformations)
      )
    }
  }

  _onCustomTransformationChange(sourceCode) {
    const displayTransformation = Object.assign(
      {},
      this.state.displayTransformation,
      { sourceCode }
    )

    const currentTransformation = Object.assign(
      {},
      this.state.currentTransformation,
      { sourceCode }
    )

    this.setState({
      displayTransformation,
      currentTransformation,
    })
  }

  _confirmCustomTransformation() {
    const { currentTransformation } = this.state

    if (currentTransformation && currentTransformation.sourceCode) {
      const mapping = (this.props.mappings || []).find(
        m => m.destination === currentTransformation.destination
      )
      const enrichment = (this.props.enrichments || []).find(
        e => e.destination === currentTransformation.destination
      )
      let type
      let initialValue

      if (enrichment) {
        initialValue =
          enrichment.value ||
          MappingsHelper._getEnrichmentValue(
            enrichment,
            this.props.mappings,
            this.state.preloadedMetadataTables,
            this.props.event
          )
        type = MappingsHelper._getTypeOfEnrichmentValue(initialValue)
      } else if (mapping && mapping.path) {
        const field = (this.props.dictionaryFields || []).find(
          d => d.name === mapping.destination
        )

        type = field.type
        initialValue = MappingsHelper._searchPathValueInEvent(
          this.props.event,
          mapping.path
        )
      } else {
        const field = (this.props.dictionaryFields || []).find(
          d => d.name === currentTransformation.destination
        )

        type = field.type
      }

      try {
        const result = MappingsHelper._executeUserSourceCode(
          currentTransformation.sourceCode,
          initialValue,
          this.props.enrichments,
          this.props.mappings,
          this.props.metadataTables,
          this.props.event
        )

        currentTransformation.value = MappingsHelper._checkDestTypeIsCompatible(
          result,
          type
        )
        currentTransformation.operator = "eval"
        delete currentTransformation.mappingRequiredError

        const transformations = this.props.transformations.slice()
        const index = this.props.transformations.findIndex(
          t => t.destination === currentTransformation.destination
        )

        if (index >= 0) {
          transformations[index] = currentTransformation
        } else {
          transformations.push(currentTransformation)
        }

        this.setState(
          {
            currentTransformation: null,
          },
          () => this._updateTransformations(transformations)
        )
      } catch (ex) {
        if (ex instanceof MappingsHelper.UserCodeException) {
          message.error(`[ERROR]: ${ex.message || ex}`)
        } else {
          console.error(`[ERROR]: ${ex}`)
        }
      }
    }
  }

  _updateTransformations(transformations) {
    const dictionaryFields = this.state.dictionaryFields.slice().map(df => {
      delete df.mapped
      return df
    })

    this.setState({ dictionaryFields }, () => {
      typeof this.props.onTransformationsUpdate === "function" &&
        this.props.onTransformationsUpdate(_.clone(transformations))
    })
  }

  _updateEnrichments(enrichments) {
    typeof this.props.onEnrichmentsUpdate === "function" &&
      this.props.onEnrichmentsUpdate(_.cloneDeep(enrichments))
  }

  _getEventForAdvancedTransformations() {
    const mappings = (this.props.mappings || []).slice()
    const enrichments = (this.props.enrichments || []).slice()
    const event = {}

    enrichments.forEach(e => {
      event[e.destination] = MappingsHelper._getEnrichmentValue(
        e,
        this.props.mappings,
        this.state.preloadedMetadataTables,
        this.props.event
      )
    })

    mappings.forEach(m => {
      if (!event.hasOwnProperty(m.destination)) {
        event[m.destination] = MappingsHelper._searchPathValueInEvent(
          this.props.event,
          m.path
        )
      }
    })

    return event
  }

  _generatePreview(
    mappings,
    transformations,
    enrichments,
    dictionaryFields,
    event,
    preloadedMetadataTables
  ) {
    return MappingsHelper._generatePreview(
      transformations,
      enrichments,
      mappings,
      dictionaryFields,
      event,
      preloadedMetadataTables
    )
  }

  _renderColumnWithTooltip(text, record) {
    let tooltipMessage = ""

    if (record.typeError) {
      tooltipMessage = this._formatMessage(
        "new-ingestion-type-incompatibilities-transformation"
      )
    } else if (record.dateError) {
      tooltipMessage = this._formatMessage("new-ingestion-date-error")
    } else if (record.tsIndexDateError) {
      tooltipMessage = this._formatMessage(
        "new-ingestion-ts-index-not-iso-8601-mapping"
      )
    } else if (record.mappingRequiredError) {
      tooltipMessage = this._formatMessage("new-ingestion-mapping-required")
    }

    if (tooltipMessage) {
      return (
        <p>
          <span style={{ marginRight: "5px" }}>{text}</span>
          <Tooltip title={tooltipMessage}>
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        </p>
      )
    } else {
      return (
        <p>
          <span>{text}</span>
        </p>
      )
    }
  }

  _renderEnrichmentsTab() {
    return (
      <>
        <Col span={8}>
          <h3>{this._formatMessage("models-metadata")}</h3>
          <p>
            <Form.Item
              label={this._formatMessage(
                "new-ingestion-context-choose-dictionary-field-label"
              )}
              style={{ marginBottom: 0 }}
            >
              <Select
                name="metadataSelectDictonaryField"
                size={"small"}
                onSelect={this._handleChangeEnrichmentField}
                placeholder={this._formatMessage(
                  "new-ingestion-context-choose-dictionary-field-placeholder"
                )}
                className="enrichments__select"
                value={this.state.enrichmentSearchValue}
              >
                {(this.props.dictionaryFields || []).map(({ name }) => {
                  return (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={this._formatMessage(
                "new-ingestion-enrichment-table-label"
              )}
              style={{ marginBottom: 0 }}
            >
              <Select
                placeholder={this._formatMessage(
                  "new-ingestion-enrichment-table-placeholder"
                )}
                onSelect={this._handleChangeEnrichmentTable}
                name="tableMetadataSelect"
                size="small"
                className="enrichments__select"
                value={this.state.enrichmentTableName}
              >
                {(this.props.metadataTables || []).map(item => {
                  const ObjKey = Object.keys(item)[0]

                  return (
                    <Option key={ObjKey} value={ObjKey}>
                      {ObjKey}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </p>
          <div className="blackboard contentLimited">
            <Table
              size="small"
              pagination={false}
              columns={this.COLUMNS_MODAL_ENRICHMENT}
              dataSource={this.state.enrichmentTable}
              onRowClick={record => this._handlePreSelectToEnrich(record)}
              rowClassName={record =>
                `transformations-row ${
                  record.mapped ? DICTIONARY_HIGHLIGHT_CLASS : ""
                }`
              }
              locale={{
                emptyText: this._formatMessage("no-data"),
              }}
            />
          </div>
        </Col>
        <Col span={6} offset={1}>
          <h3>{this._formatMessage("models-dictionary")}</h3>
          <Table
            className="ingests-table-mappings"
            size="small"
            pagination={false}
            columns={this.COLUMNS_MODAL_MAPPING_ENRICHMENT}
            dataSource={this.props.dictionaryFields}
            rowClassName={record => {
              const mapped =
                this.props.mappings.findIndex(
                  mapping => mapping.destination === record.name
                ) !== -1
              return `transformations-row ${
                mapped ? DICTIONARY_HIGHLIGHT_CLASS : ""
              }`
            }}
            onRowClick={record => this._handleSelectEnrich(record)}
            locale={{
              emptyText: this._formatMessage("no-data"),
            }}
          />
        </Col>
        <Col span={8} offset={1}>
          <h3>{this._formatMessage("preview")}</h3>
          <Table
            className="ingests-table-mappings"
            size="small"
            pagination={false}
            columns={this.COLUMNS_MODAL_MAPPING_PREVIEW}
            dataSource={this._generatePreview(
              this.props.mappings,
              this.props.transformations,
              this.props.enrichments,
              this.props.dictionaryFields,
              this.props.event,
              this.state.preloadedMetadataTables
            )}
            rowClassName={record =>
              record.typeError || record.dateError || record.tsIndexDateError
                ? MAPPING_TYPE_ERROR_CLASS
                : ""
            }
            locale={{
              emptyText: this._formatMessage("no-data"),
            }}
          />
        </Col>
      </>
    )
  }

  _renderTransformationsTab() {
    return (
      <Row>
        <Col span={5}>
          <h3>{this._formatMessage("models-dictionary")}</h3>
          <Table
            size="small"
            pagination={false}
            columns={this.COLUMNS_MODAL_MAPPING_FROM_TRANSFORMATION}
            onRowClick={row =>
              this._onSelectDictonaryFieldForTransformation(row)
            }
            dataSource={
              Array.isArray(this.state.dictionaryFields) &&
              this.state.dictionaryFields.length
                ? this.state.dictionaryFields
                : this.props.dictionaryFields
            }
            rowClassName={record =>
              `transformations-row ${
                record.mapped ? DICTIONARY_HIGHLIGHT_CLASS : ""
              }`
            }
          />
        </Col>
        <Col span={12} offset={1}>
          <h3>{this._formatMessage("new-ingestion-transformations")}</h3>
          <Collapse
            onChange={this._onCollapseChange}
            activeKey={
              this._originAndDestinationAreValid()
                ? this.state.selectedTab
                : undefined
            }
            accordion
          >
            <Panel
              disabled={!this._originAndDestinationAreValid()}
              header={
                <>
                  <span>
                    {this._formatMessage(
                      "new-ingestion-transformation-primitive-types-header"
                    )}
                  </span>
                  <Tooltip
                    title={
                      <span>
                        {this._formatMessage(
                          "new-ingestion-transformation-primitive-types-tooltip"
                        )}
                      </span>
                    }
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginLeft: "5px",
                      }}
                    />
                  </Tooltip>
                </>
              }
              key="1"
            >
              <Form.Item style={{ marginBottom: "5px" }}>
                <label
                  className="transformation-desc"
                  dangerouslySetInnerHTML={{
                    __html: `${this._formatMessage(
                      "new-ingestion-transformation-primitive-types-label"
                    )}:`,
                  }}
                />
                <Select
                  value={this.state.displayTransformation.outputType}
                  placeholder={this._formatMessage(
                    "new-ingestion-transformation-primitive-types-placeholder"
                  )}
                  onSelect={this._onGenericCastingSelect}
                >
                  {GENERIC_CASTINGS.map(({ value, label }) => (
                    <Option value={value}>{label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Button
                type="primary"
                onClick={this._confirmGenericCasting}
                style={{ marginTop: 10 }}
              >
                {this._formatMessage("apply")}
              </Button>
            </Panel>

            <Panel
              key="2"
              disabled={!this._originAndDestinationAreValid()}
              header={
                <>
                  <span>
                    {this._formatMessage(
                      "new-ingestion-transformation-dates-header"
                    )}
                  </span>
                  <Tooltip
                    title={this._formatMessage(
                      "new-ingestion-transformation-dates-tooltip"
                    )}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginLeft: "5px",
                      }}
                    />
                  </Tooltip>
                </>
              }
            >
              <Form.Item style={{ marginBottom: "5px" }}>
                <div className="transformation-desc">
                  {this._formatMessage(
                    "new-ingestion-transformation-dates-date-format-title"
                  )}
                  :
                  <Tooltip
                    title={this._formatMessage(
                      "new-ingestion-transformation-dates-date-format-tooltip"
                    )}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginLeft: "5px",
                      }}
                    />
                  </Tooltip>
                </div>
                <Input
                  name="date-format"
                  onChange={this._onDateCastingInput}
                  placeholder="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                  value={this.state.displayTransformation.dateFormat}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: "5px" }}>
                <div className="transformation-desc">
                  {this._formatMessage(
                    "new-ingestion-transformation-dates-date-zone-title"
                  )}
                  ::
                  <Tooltip
                    title={this._formatMessage(
                      "new-ingestion-transformation-dates-date-zone-tooltip"
                    )}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginLeft: "5px",
                      }}
                    />
                  </Tooltip>
                </div>
                <TimezoneSelector onChange={this._onTimezoneChange} />
                <div
                  className="transformation-desc"
                  dangerouslySetInnerHTML={{
                    __html: this._formatMessage(
                      "new-ingestion-transformation-dates-help"
                    ),
                  }}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={this._confirmDateCasting}
                style={{ marginTop: "10px" }}
              >
                {this._formatMessage("apply")}
              </Button>
            </Panel>
            <Panel
              disabled={!this._originAndDestinationAreValid()}
              header={
                <>
                  <span>
                    {this._formatMessage(
                      "new-ingestion-transformation-custom-header"
                    )}
                  </span>
                  <Tooltip
                    title={this._formatMessage(
                      "new-ingestion-transformation-custom-tooltip"
                    )}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginLeft: "5px",
                      }}
                    />
                  </Tooltip>
                </>
              }
              key="3"
            >
              <Form.Item style={{ marginBottom: "5px" }}>
                <div className="transformation-desc">
                  {this._formatMessage(
                    "new-ingestion-transformation-custom-help-1"
                  )}
                  <Tooltip
                    title={this._formatMessage(
                      "new-ingestion-transformation-custom-help-tooltip"
                    )}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        color: "rgba(0,0,0,.45)",
                        margin: "0 5px 0 5px",
                      }}
                    />
                  </Tooltip>
                  {this._formatMessage(
                    "new-ingestion-transformation-custom-help-2"
                  )}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/help/advanced-transformations"
                  >
                    {this._formatMessage("link")}
                  </a>
                  .
                </div>
                <AceEditor
                  mode="javascript"
                  name="custom-transformation"
                  theme="textmate"
                  onChange={this._onCustomTransformationChange}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={
                    (this.state.displayTransformation &&
                      this.state.displayTransformation.sourceCode) ||
                    FUNCTION_EXAMPLE
                  }
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    fontSize: 12,
                  }}
                />
              </Form.Item>
              <Button
                type="primary"
                disabled={
                  !this.state.displayTransformation ||
                  !this.state.displayTransformation.sourceCode
                }
                onClick={this._confirmCustomTransformation}
                style={{ marginTop: "10px" }}
              >
                {this._formatMessage("apply")}
              </Button>
            </Panel>
          </Collapse>
        </Col>
        <Col span={5} offset={1}>
          <h3>{this._formatMessage("preview")}</h3>
          <Table
            size="small"
            pagination={false}
            columns={this.COLUMNS_TRANSFORMATION_PREVIEW}
            dataSource={this._generatePreview(
              this.props.mappings,
              this.props.transformations,
              this.props.enrichments,
              this.props.dictionaryFields,
              this.props.event,
              this.state.preloadedMetadataTables
            )}
            rowClassName={record =>
              record.typeError ||
              record.dateError ||
              record.tsIndexDateError ||
              record.mappingRequiredError
                ? MAPPING_TYPE_ERROR_CLASS
                : ""
            }
          />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Row>
        <Row
          style={{
            marginLeft: 0,
          }}
        >
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={this._formatMessage("new-ingestion-enrichments")}
              key="1"
            >
              {this._renderEnrichmentsTab()}
            </TabPane>
            <TabPane
              tab={this._formatMessage("new-ingestion-transformations")}
              key="2"
            >
              {this._renderTransformationsTab()}
            </TabPane>
          </Tabs>
        </Row>
      </Row>
    )
  }
}

export default injectIntl(IngestOthers)
