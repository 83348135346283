import React from "react"
import { Col, Form, message, Row, Spin, Steps } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import _ from "lodash"

import { INGEST_TYPES } from "../../services/constants"
import { callbackRolesErrorMessages } from "../../services/roles/rolesRequestErrorMessages"
import IngestDataForm from "./ingestDataForm"
import IngestDictionaryForm from "./ingestDictionaryForm"
import IngestContextSelector from "./ingestContextSelector"
import IngestEventForm from "./ingestEventForm"
import IngestMappings from "./ingestMappings"
import IngestOthers from "./ingestOthers"
import IngestActivate from "./ingestActivate"
import * as IngestService from "../../services/ingest"

const { Step } = Steps
const MARGIN = 40
const FIXED_OPTION = "fixed"
const FILTERED_OPTION = "filtered"
const INFORMED_OPTION = "informed"

class RtNrtIngestForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      publicationsPerSecondLimit: null,
      roles: [],
      sites: [],
      sources: [],
      prefixes: [],
      context: null,
      metadataTables: [],
      publicationsPerSecond: 0,
      minPublicationsPerSecond: 0,
      accumulatorRequired: false,
      editMode: false,
      disabledSteps: true,
      mappings: null,
      enrichments: null,
      transformations: null,
      event: null,
      businessUnits: [],
    }

    this.ingest = null

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this._onStepChange = this._onStepChange.bind(this)
    this._renderCurrentStep = this._renderCurrentStep.bind(this)
    this._onNewIngestContextChange = this._onNewIngestContextChange.bind(this)
    this._onDictionarySelect = this._onDictionarySelect.bind(this)
    this._onEventChange = this._onEventChange.bind(this)
    this._onPublicationsPerSecondChange = this._onPublicationsPerSecondChange.bind(
      this
    )
    this._goToNextStep = this._goToNextStep.bind(this)
    this._getIngest = this._getIngest.bind(this)
    this._onMappingsChange = this._onMappingsChange.bind(this)
    this._changeCurrentStepAfterError = this._changeCurrentStepAfterError.bind(
      this
    )
    this._onEnrichmentsUpdate = this._onEnrichmentsUpdate.bind(this)
    this._onTransformationsUpdate = this._onTransformationsUpdate.bind(this)
    this._onPropertyChange = this._onPropertyChange.bind(this)
  }

  componentDidMount() {
    const editMode = !!(this.props.ingest && this.props.ingest.alias)
    const dictionaries = (editMode
      ? IngestService.getDictionaries({
          compatibleWithIngestAlias: this.props.ingest.alias,
        })
      : IngestService.getDictionaries()
    ).then(dictionaries => this.setState({ dictionaries }))
    const publicationsPerSecondLimit = IngestService.getPublicationsPerSecondLimit().then(
      publicationsPerSecondLimit =>
        this.setState({ publicationsPerSecondLimit })
    )
    const sites = IngestService.getSites().then(sites =>
      this.setState({ sites })
    )
    const sources = IngestService.getSources().then(sources =>
      this.setState({ sources })
    )
    const prefixes = IngestService.getPrefixes().then(prefixes =>
      this.setState({ prefixes })
    )
    const roles = IngestService.getRoles()
      .then(roles => this.setState({ roles: roles.map(role => role.name) }))
      .catch(_ =>
        callbackRolesErrorMessages(response, "LIST").then(text =>
          message.error(this.props.intl.formatMessage({ id: text }))
        )
      )
    const metadataTables = IngestService.getMetadataTables().then(
      metadataTables => this.setState({ metadataTables })
    )
    const businessUnits = IngestService.getBusinessUnits().then(businessUnits =>
      this.setState({ businessUnits })
    )
    this._setConfirmInformedContext()
    this.setState({ editMode })
    return IngestService.getTakenAliases()
      .then(ingestAliases => this.setState({ ingestAliases }))
      .then(_ => {
        return Promise.all([
          dictionaries,
          metadataTables,
          publicationsPerSecondLimit,
          sites,
          sources,
          prefixes,
          roles,
          businessUnits,
        ])
      })
      .then(_ => this.setState({ disabledSteps: false }))
      .catch(_ =>
        message.error(this._formatMessage("new-ingestion-page-load-error"))
      )
  }

  get STEPS() {
    return [
      this._formatMessage("new-ingestion-config-step"),
      this._formatMessage("new-ingestion-dict-step"),
      this._formatMessage("new-ingestion-context-step"),
      this._formatMessage("new-ingestion-event-step"),
      this._formatMessage("new-ingestion-mapping-step"),
      this._formatMessage("new-ingestion-others-step"),
    ]
  }

  _getDictionary() {
    const { dictionaryId } = this._getIngest()
    if (Array.isArray(this.state.dictionaries) && dictionaryId) {
      return this.state.dictionaries.find(({ id }) => id === dictionaryId)
    } else {
      return null
    }
  }

  _onPropertyChange = (property, value) => {
    const ingest = this._getIngest()
    _.merge(ingest, { [property]: value })
    this.ingest = ingest
  }

  _onNewIngestContextChange(context = {}) {
    const ingest = this._getIngest()
    _.merge(ingest.context, { ...context })
    this.ingest = ingest
    this.setState({ context: _.cloneDeep(this.ingest.context) })
  }

  _onDictionarySelect(dictionaryId) {
    const ingest = this._getIngest()
    const newDictname = dictionaryId.split(":").shift()
    const oldDictName = ((ingest && ingest.dictionaryId) || "")
      .split(":")
      .shift()
    if (newDictname !== oldDictName) {
      delete ingest.mappings
      delete ingest.enrichments
      delete ingest.transformations
      _.merge(ingest, {
        mappings: [],
        enrichments: [],
        transformations: [],
      })
      this.setState({ mappings: [], enrichments: [], transformations: [] })
    }

    this.ingest = Object.assign({}, ingest, { dictionaryId })
  }

  _onEventChange(newEvent) {
    const ingest = this._getIngest()
    if (!_.isEqual(newEvent, ingest.event)) {
      delete ingest.mappings
      delete ingest.enrichments
      delete ingest.transformations
      _.merge(ingest, {
        mappings: [],
        enrichments: [],
        transformations: [],
      })
    }
    const event = {
      event: newEvent,
      fileType: "JSON",
    }
    _.merge(ingest, { event })
    this.ingest = ingest
    this.setState({ event, mappings: [], enrichments: [], transformations: [] })
  }

  _onPublicationsPerSecondChange({
    publicationsPerSecond,
    accumulatorRequired,
  }) {
    const ingest = this._getIngest()
    _.merge(ingest, { publicationsPerSecond, accumulatorRequired })
    this.ingest = ingest
  }

  _getIngest() {
    const ingest = {}
    return _.merge(ingest, this.props.ingest || {}, this.ingest || {})
  }

  _onMappingsChange(mappings) {
    const ingest = this._getIngest()
    const destinations = Array.from(new Set(mappings.map(m => m.destination)))
    const enrichments = (ingest.enrichments || []).filter(({ destination }) => {
      return !destinations.includes(destination)
    })
    const transformations = (ingest.transformations || []).filter(
      ({ destination }) => {
        return !destinations.includes(destination)
      }
    )
    delete ingest.mappings
    delete ingest.enrichments
    delete ingest.transformations
    _.merge(ingest, { mappings, enrichments, transformations })
    this.ingest = ingest
    this.setState({ mappings, enrichments, transformations })
  }

  _onEnrichmentsUpdate(enrichments) {
    const ingest = this._getIngest()
    delete ingest.enrichments
    _.merge(ingest, { enrichments })
    this.ingest = ingest
    this.setState({ enrichments })
  }

  _onTransformationsUpdate(transformations) {
    const ingest = this._getIngest()
    delete ingest.transformations
    _.merge(ingest, { transformations })
    this.ingest = ingest
    this.setState({ transformations })
  }

  _setConfirmInformedContext() {
    const ingest = this._getIngest()
    const { context = {} } = ingest
    context.confirmInformedContext =
      (context && context.option) === INFORMED_OPTION
    _.merge(ingest, { context })
    this.ingest = ingest
  }

  _goToNextStep(currentStep) {
    return () => {
      ++currentStep
      this.setState({ currentStep })
    }
  }

  _onStepChange(currentStep) {
    this.setState({ currentStep })
  }

  _renderCurrentStep() {
    const dictionary = this._getDictionary()
    const ingest = this._getIngest()
    let event = this.state.event || (ingest && ingest.event)

    switch (this.state.currentStep) {
      case 0:
        return (
          <Col span={24}>
            <IngestDataForm
              editing={this.state.editMode}
              type={INGEST_TYPES["rt"]}
              disableAlias={this.props.ingest && !!this.props.ingest.alias}
              id={ingest && ingest.id}
              name={ingest && ingest.name}
              alias={ingest && ingest.alias}
              description={ingest && ingest.description}
              version={ingest && ingest.version}
              versions={
                ingest &&
                Array.isArray(ingest.versions) &&
                ingest.versions.map(({ version }) => version)
              }
              tags={ingest && ingest.tags}
              combinedIngest={ingest && ingest.linked_with}
              takenAliases={this.state.ingestAliases}
              roles={this.state.roles}
              rolesSelected={ingest && ingest.roles ? ingest.roles : []}
              onNameChange={value => this._onPropertyChange("name", value)}
              onAliasChange={value => this._onPropertyChange("alias", value)}
              onVersionChange={value =>
                this._onPropertyChange("version", value)
              }
              onDescriptionChange={value =>
                this._onPropertyChange("description", value)
              }
              onTagsUpdate={value => this._onPropertyChange("tags", value)}
              onRolesChange={value => this._onPropertyChange("roles", value)}
              onNextStep={this._goToNextStep(0)}
              hasDatabaseHot={ingest && ingest.hasDatabaseHot}
              hasDatabaseCold={ingest && ingest.hasDatabaseCold}
              retentionPeriodHot={ingest && ingest.retentionPeriodHot}
              retentionPeriodCold={ingest && ingest.retentionPeriodCold}
              onDatabaseHotChange={value =>
                this._onPropertyChange("hasDatabaseHot", value)
              }
              onDatabaseColdChange={value =>
                this._onPropertyChange("hasDatabaseCold", value)
              }
              onRetentionPeriodHotChange={value =>
                this._onPropertyChange("retentionPeriodHot", value)
              }
              onRetentionPeriodColdChange={value =>
                this._onPropertyChange("retentionPeriodCold", value)
              }
              businessUnits={this.state.businessUnits}
              onBusinessUnitChange={value =>
                this._onPropertyChange("businessUnit", value)
              }
              businessUnit={ingest && ingest.businessUnit}
              onAdditionalInfoChange={value =>
                this._onPropertyChange("additionalInfo", value)
              }
              additionalInfo={ingest && ingest.additionalInfo}
            />
          </Col>
        )
      case 1:
        return (
          <Col span={24}>
            {Array.isArray(this.state.dictionaries) ? (
              <IngestDictionaryForm
                editing={this.state.editMode}
                combinedIngest={ingest && ingest.linked_with}
                dictionaries={this.state.dictionaries}
                selectedDictionaryId={dictionary && dictionary.id}
                onDictionarySelect={this._onDictionarySelect}
                onNextStep={this._goToNextStep(1)}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin size="large" />
              </div>
            )}
          </Col>
        )
      case 2:
        const context = this.state.context || (ingest && ingest.context)
        let selectedTab = 1
        switch (context.option) {
          case FIXED_OPTION:
            selectedTab = 1
            break
          case FILTERED_OPTION:
            selectedTab = 2
            break
          case INFORMED_OPTION:
            selectedTab = 3
            break
          default:
            selectedTab = 1
        }
        return (
          <Col span={16}>
            <IngestContextSelector
              sites={this.state.sites}
              sources={this.state.sources}
              prefixes={this.state.prefixes}
              site={context.site}
              source={context.source}
              prefix={context.prefix}
              delimiter={context.delimiter}
              fieldName={context.fieldName}
              option={context.option}
              dictionaryFields={dictionary && dictionary.fields}
              selectedTab={selectedTab}
              onContextChange={this._onNewIngestContextChange}
              onNextStep={this._goToNextStep(2)}
            />
          </Col>
        )
      case 3:
        return (
          <Col span={16}>
            {this.state.publicationsPerSecondLimit === null ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin size="large" />
              </div>
            ) : (
              <IngestEventForm
                type={INGEST_TYPES["rt"]}
                publicationsPerSecondLimit={
                  this.state.publicationsPerSecondLimit
                }
                publicationsPerSecond={ingest && ingest.publicationsPerSecond}
                event={event}
                onEventChange={this._onEventChange}
                onPublicationsPerSecondChange={
                  this._onPublicationsPerSecondChange
                }
                onNextStep={this._goToNextStep(3)}
              />
            )}
          </Col>
        )
      case 4:
        return (
          <IngestMappings
            dictionaryFields={dictionary && dictionary.fields}
            event={
              event && Array.isArray(event.event)
                ? event.event.shift()
                : event.event
            }
            mappings={this.state.mappings || (ingest && ingest.mappings)}
            enrichments={
              this.state.enrichments || (ingest && ingest.enrichments)
            }
            transformations={
              this.state.transformations || (ingest && ingest.transformations)
            }
            onNextStep={this._goToNextStep(4)}
            onMappingsChange={this._onMappingsChange}
          />
        )
      case 5:
        return (
          <IngestOthers
            metadataTables={this.state.metadataTables}
            dictionaryFields={dictionary && dictionary.fields}
            event={
              event && Array.isArray(event.event)
                ? event.event.shift()
                : event.event
            }
            mappings={this.state.mappings || (ingest && ingest.mappings)}
            enrichments={
              this.state.enrichments || (ingest && ingest.enrichments)
            }
            transformations={
              this.state.transformations || (ingest && ingest.transformations)
            }
            onEnrichmentsUpdate={this._onEnrichmentsUpdate}
            onTransformationsUpdate={this._onTransformationsUpdate}
          />
        )
      default:
        return null
    }
  }

  _changeCurrentStepAfterError(step) {
    this.setState({ currentStep: step })
  }

  _getStepDisabled(step) {
    if (this.state.editMode) {
      return this.state.disabledSteps
    } else {
      return step > this.state.currentStep
    }
  }

  _getStepTitle(step, title) {
    return step === this.state.currentStep ? (
      <div style={{ color: "#5731F9" }}>{title}</div>
    ) : (
      title
    )
  }

  _getStepDescription(step) {
    return step === this.state.currentStep ? (
      <div style={{ borderBottom: "1px solid #27D098", width: 20 }} />
    ) : null
  }

  render() {
    const ingest = this._getIngest()

    return (
      <Form className="formCustom">
        <div className="content">
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              <Steps
                size="small"
                current={this.state.currentStep}
                direction="horizontal"
                onChange={this._onStepChange}
              >
                {this.STEPS.map((title, index) => (
                  <Step
                    disabled={this._getStepDisabled(index)}
                    key={title}
                    title={this._getStepTitle(index, title)}
                    description={this._getStepDescription(index)}
                  />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: MARGIN,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this._renderCurrentStep()}
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <IngestActivate
              ingest={ingest}
              goToStep={this._changeCurrentStepAfterError}
            />
          </Col>
        </Row>
      </Form>
    )
  }
}

export default injectIntl(RtNrtIngestForm)
